import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import moment from "moment";

import Break from '../../Assets/Break.png';
import SlotCard from "../Slots/SlotCard/SlotCard";
import Spinner from "../Common/Tailwind/Spinner";
import MessagePage from "../Common/Tailwind/Page/MessagePage";

import { STUDENT_USER_TYPE } from "../../services/UserService";
import { securedFetchSlots } from "../../services/BatchService";
import { securedFetchNextSlot } from "../../services/TimetableService";
import { navigateCallbackOptions } from "../../services/AuthenticationService";

const DashboardLectureInfo = ({ user = STUDENT_USER_TYPE }) => {

    const [nextClassInfo, setNextClassInfo] = useState({});
    const [allClasses, setAllClasses] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const timetableFilter = {
                start_time: moment().startOf('day').format(),
                end_time: moment().endOf('day').format()
            };
            const fetchedAllClassesForToday = await securedFetchSlots(timetableFilter, navigateCallbackOptions(navigate));
            if (fetchedAllClassesForToday === null) {
                setIsLoading(false);
                return;
            }
            setAllClasses(fetchedAllClassesForToday.data);
            const fetchedNextClassInfo = await securedFetchNextSlot({ end_time: timetableFilter.end_time }, navigateCallbackOptions(navigate));
            if (fetchedNextClassInfo === null) {
                setIsLoading(false);
                return;
            }
            setNextClassInfo(fetchedNextClassInfo);
            setIsLoading(false);
        })();
    }, [])

    const renderSingleLecture = (lecture) => {

        let finished = nextClassInfo.start_time > lecture.start_time;
        let current = nextClassInfo.id === lecture.id;
        let nomorelectures = (nextClassInfo === null || nextClassInfo.start_time == null);

        console.log(finished, current);

        return (
            <SlotCard
                slotData={lecture}
                user={user}
                slotStatus={finished ? "FINISHED" : current ? "CURRENT" : nomorelectures ? "FINISHED" : ""}
            />
        )
    }

    if (allClasses === null || isLoading) {
        return <Spinner />;
    }

    return (
        <div class="flex flex-col py-4 mt-4 overflow-x-auto overflow-y-auto md:justify-center dark:border-gray-700">
            {allClasses.length === 0 ? (
                <MessagePage imgsrc={Break} title={"NO lecture"} message1={"You have"} message2={"today!"} message3={"Review your progress instead"} />
            ) : (
                <div className="flex flex-col gap-2">
                    <h3 class="text-xl font-semibold text-center text-[var(--SecondaryColor)] capitalize lg:text-2xl dark:text-white">
                        {t("lecturesToday")}
                    </h3>

                    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                        {allClasses.map((lecture) => (
                            <li
                                key={lecture.id}
                                className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow-sm"
                            >
                                {renderSingleLecture(lecture)}
                            </li>
                        ))}
                    </ul>
                </div>
            )}

        </div>

    )
}

export default DashboardLectureInfo