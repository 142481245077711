import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from "react-i18next";

import './Syllabus.css'

import SyllabusQuestions from './SyllabusQuestions';
import SidebarLogo from "../../Assets/Syllabus.png";
import SyllabusSidebarItems from "./Sidebar/SidebarItems";
import SelectionDropdownMultiple from '../Common/SelectionDropdownMultiple';
import SyllabusSlots from './SyllabusSlots';
import { securedFetchUserById, ADMIN_USER_TYPE, TEACHER_USER_TYPE, STUDENT_USER_TYPE } from "../../services/UserService";
import { navigateCallbackOptions } from '../../services/AuthenticationService';
import { securedFetchSubjects, securedFetchTopicsByFilter } from '../../services/SyllabusService';
import { securedFetchGrades } from '../../services/BatchService';
import SyllabusFilter from './SyllabusFilter';
import SyllabusReadingMaterials from './SyllabusReadingMaterial';
import SyllabusRecordedVideos from './SyllabusRecordedVideos';
import { useAuth } from '../Authentication/AuthContext';
import Spinner from '../Common/Tailwind/Spinner';
import PageSidebar from '../Common/Tailwind/Page/PageSidebar';
import PageHeader from '../Common/Tailwind/Page/PageHeader';


const Syllabus = ({ user = ADMIN_USER_TYPE }) => {

    const [userInfo, setUserInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [selectedTab, setSelectedTab] = useState("questions");
    const [subjects, setSubjects] = useState([]);
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [grades, setGrades] = useState([]);
    const [selectedGrades, setSelectedGrades] = useState([]);
    const [mainTopics, setMainTopics] = useState([]);
    const [selectedMainTopics, setSelectedMainTopics] = useState([]);
    const [topics, setTopics] = useState([]);
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [syllabusFilter, setSyllabusFilter] = useState(null);
    const navigate = useNavigate();

    const {
        config
    } = useAuth();

    const { t } = useTranslation();

    const handleSubmit = async () => {
        setSyllabusFilter(makeSyllabusFilter);
    }

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const fetchedUserInfo = await securedFetchUserById(navigateCallbackOptions(navigate));
            if (fetchedUserInfo === null) {
                return;
            }
            setUserInfo(fetchedUserInfo);
            setSyllabusFilter({ subjects: [], selectedSubjects: [], mainTopics: [], selectedMainTopics: [], topics: [], selectedTopics: [] });
            setIsLoading(false);
        })();
    }, []);

    useEffect(() => {
        setSyllabusFilter(makeSyllabusFilter());
    }, [topics, selectedTopics, subjects, selectedSubjects, mainTopics, selectedMainTopics]);

    const makeSyllabusFilter = () => {
        return {
            subjects: subjects,
            selectedSubjects: selectedSubjects,
            grades: grades,
            selectedGrades: selectedGrades,
            mainTopics: mainTopics,
            selectedMainTopics: selectedMainTopics,
            topics: topics,
            selectedTopics: selectedTopics
        }
    }

    const renderMain = () => {
        if (isLoading) {
            return <Spinner />;
        }
        return (
            <main className="w-full h-full md:py-10">
                <div className="flex flex-col flex-1 justify-center items-center p-2 md:p-4 gap-2">

                    {/* Topic Filter */}
                    <SyllabusFilter subjects={subjects} setSubjects={setSubjects} selectedSubjects={selectedSubjects} setSelectedSubjects={setSelectedSubjects}
                        grades={grades} setGrades={setGrades} selectedGrades={selectedGrades} setSelectedGrades={setSelectedGrades}
                        mainTopics={mainTopics} setMainTopics={setMainTopics} selectedMainTopics={selectedMainTopics} setSelectedMainTopics={setSelectedMainTopics}
                        topics={topics} setTopics={setTopics} selectedTopics={selectedTopics} setSelectedTopics={setSelectedTopics} user={user} />

                    {/* Question */}
                    {syllabusFilter ? (
                        <>
                            {userInfo && (selectedTab === "questions") && (
                                <SyllabusQuestions syllabusFilter={syllabusFilter} user={user} />
                            )}

                            {/* Video */}
                            {userInfo && (selectedTab === "lectures") && (
                                <SyllabusSlots syllabusFilter={syllabusFilter} user={user} />
                            )}

                            {/* Doubts */}
                            {userInfo && (selectedTab === "doubts") && (
                                <>
                                    {/* <Details /> */}
                                </>
                            )}

                            {/* Texts */}
                            {userInfo && (selectedTab === "readings") && (
                                <SyllabusReadingMaterials syllabusFilter={syllabusFilter} user={user} />
                            )}

                            {userInfo && (selectedTab === "videos") && (
                                <SyllabusRecordedVideos syllabusFilter={syllabusFilter} user={user} />
                            )}

                            {/* Quizzes */}
                            {userInfo && (selectedTab === "quizzes") && (
                                <>
                                    {/* <Details /> */}
                                </>
                            )}
                        </>)
                        : (
                            <Spinner />
                        )}
                </div>
            </main>
        )
    }

    return (
        <div className="relative flex flex-col flex-1 w-full h-full items-center gap-2 sm:gap-3 md:gap-4 text-[var(--textColor)]">

            {/* Page Header */}
            {/* <PageHeader pageHeaderText={t("masterTopics")} /> */}

            {/* Page Main */}
            <div className="flex flex-col md:flex-row w-full bg-white overflow-hidden">

                {/* Sidebar */}
                <PageSidebar sidebarLogo={SidebarLogo} sidebarTitle="Syllabus" sidebarItems={SyllabusSidebarItems} user={user} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

                {/* Main */}
                {renderMain()}
            </div>

        </div>
    );
};

export default Syllabus;