import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import logo from '../../Assets/FullLogo_Transparent.png'
import Student from '../../Assets/Student.png'
import Teacher from '../../Assets/Teacher.png'
import Admin from '../../Assets/Admin.png'
import Hero from '../../Components/Hero/Hero'
import LoginForm from '../../Components/Authentication/LoginForm';
import { loginWithPassword, navigateCallbackOptions } from '../../services/AuthenticationService';
import { checkUserType, securedFetchUserById } from '../../services/UserService';
import { useAuth } from '../../Components/Authentication/AuthContext';

const Home = () => {

  const profiles = [
    { value: Admin, label: "Admin", description: "Transform Your Institute with JEETA", uri: 'admin', navigateTo: '/admin/dashboard' },
    { value: Teacher, label: "Teacher", description: "Innovate Your Teaching with JEETA", uri: 'teacher', navigateTo: '/teacher/dashboard' },
    { value: Student, label: "Student", description: "Achieve Your Dream with JEETA", uri: 'student', navigateTo: '/student/dashboard' },
  ];

  const [selectedProfile, setSelectedProfile] = useState({ value: logo, label: '', description: '', uri: '', navigateTo: '' });
  const [showLogIn, setShowLogIn] = useState(false);

  const navigate = useNavigate();

  const {
    userType, setUserType,
    config, setConfig
  } = useAuth();

  const onSeclectedProfileChange = (selected) => {
    setSelectedProfile(selected[0]);
    setShowLogIn(true);
  }

  useEffect(() => {

    (async () => {
      const errorCallback = {
        authErrorCallback: () => {
          return;
        }
      }
      const fetchedUserInfo = await securedFetchUserById(errorCallback);
      if (fetchedUserInfo === null) {
        return;
      }
      const currentUserType = checkUserType(fetchedUserInfo);
      setUserType(currentUserType)
      navigate(`/${currentUserType}/dashboard`)
    })();
  }, []);

  const handleLogin = async (event, credentials) => {
    event.preventDefault();
    if (credentials.username.length === 0 || credentials.password.length === 0) {
      return;
    }
    const response = await loginWithPassword(selectedProfile.uri, credentials);
    if (response === null) {
      return;
    }
    setUserType(selectedProfile.uri);
    const data = await response.json();
    if (response.ok) {
      navigate(selectedProfile.navigateTo);
    } else {
      alert(data.detail);
    }
  };

  const selectProfile = () => {
    return (
      <div class="w-full flex flex-col mt-4 py-2 md:justify-center dark:border-gray-700">

        <div class="flex justify-center items-center">
          {profiles.map((profile, index) => (
            <button
              key={index}
              className={`h-10 md:h-12 px-4 md:px-8 py-2 -mb-px text-sm text-center border-b-2 sm:text-base whitespace-nowrap focus:outline-none transition-opacity duration-3500 ease-linear data-[closed]:opacity-0
          ${selectedProfile.label === profile.label
                  ? 'text-[var(--PrimaryColor)] border-[var(--PrimaryColor)] font-semibold dark:border-blue-400 dark:text-blue-300'
                  : 'text-[var(--textColor)] border-gray-200  dark:text-white cursor-pointer dark:border-gray-700 dark:hover:border-gray-400 hover:border-gray-400 hover:font-semibold'
                }`}
              onClick={() => onSeclectedProfileChange([profile])}
            >
              {profile.label}
            </button>
          ))}
        </div>

      </div>
    )
  }

  const handleRegister = (event) => {
  };

  return (

    <main class="absolute top-0 left-0 w-screen min-h-screen bg-white flex flex-col items-center transition-opacity duration-300 ease-out data-[closed]:opacity-0 data-[enter]:opacity-100">
      {showLogIn ? (
        <Hero
          cName="max-w-[70vw] max-h-[45vh]"
          img={selectedProfile.value}
          title={selectedProfile.label}
          description={selectedProfile.description}
        />
      ) : (
        <Hero
          cName="max-w-[90vw] max-h-[60vh]"
          img={selectedProfile.value}
          title={selectedProfile.label}
          description={selectedProfile.description}
        />
      )}

      {selectProfile()}

      {selectedProfile.label &&
        <LoginForm handleLogin={handleLogin} handleRegister={handleRegister} showLogIn={showLogIn} />
      }

    </main>
  )
};

export default Home;
