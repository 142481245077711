import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import moment from "moment";
import i18n from '../../multi-language/i18n'; // Import the i18n instance
import { useTranslation } from 'react-i18next';

import jeetaLogo from '../../Assets/IconOnly_Transparent_NoBuffer.png'
import ProfilePopup from '../Profile/ProfilePopup';

import { ADMIN_USER_TYPE, TEACHER_USER_TYPE, hasAdminRoleInfo, hasBatchInfo, hasSubjectInfo, securedFetchUserById, securedFetchUsers } from '../../services/UserService';
import { navigateCallbackOptions, securedLogoutUser } from "../../services/AuthenticationService";
import { securedFetchSlots } from "../../services/BatchService";
import { securedFetchNextSlot } from "../../services/TimetableService";

import NavbarIconGenerator from './NavbarIconGenerator';
import { PiHandWaving } from "react-icons/pi";
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { GlobeAltIcon, MagnifyingGlassIcon, UserCircleIcon } from '@heroicons/react/20/solid'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Feedback from '../Feedback/Feedback';
import PINCode from '../Common/PINCode';

const Navbar = ({ navItems, user = ADMIN_USER_TYPE }) => {

  const [isIconVisible, setIsIconVisible] = useState(window.innerWidth < 860);
  const [isProfilePopupOpen, setProfilePopupOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [nextClassInfo, setNextClassInfo] = useState({});
  const [openFeedback, setOpenFeedback] = useState(false);
  const [show, setShow] = useState(true)
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      if (userInfo) return;

      const fetchedUserInfo = await securedFetchUserById(navigateCallbackOptions(navigate));
      if (fetchedUserInfo === null) {
        return;
      }
      setUserInfo(fetchedUserInfo);
      const endTimeForToday = moment().format('YYYY-MM-DD 23:59:59');
      const fetchedNextClass = await securedFetchNextSlot({ end_time: endTimeForToday }, navigateCallbackOptions(navigate));
      if (fetchedNextClass === null) {
        return;
      }
      setNextClassInfo(fetchedNextClass);
      setOpenFeedback(false);
    })();
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language using i18next
  };

  const onLogout = async () => {
    const response = await securedLogoutUser(navigateCallbackOptions(navigate));
    // onRequestClose();
    if (response === null) {
      return;
    }
    navigate('/');
  }

  const onFeedbackSend = () => {
    // onRequestClose();
  }

  const onRequestFeedbackClose = () => {
    setOpenFeedback(false);
  }

  useEffect(() => {
    const handleResize = () => {
      setIsIconVisible(window.innerWidth < 860);
    };

    // Attach the event listener when the component mounts
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleProfilePopup = () => {
    setProfilePopupOpen(!isProfilePopupOpen);
  };

  const dashboardItem = navItems.find(item => item.title === "dashboard");
  const profileItem = navItems.find(item => item.title === "profile");


  const baseLargeButton = "inline-flex items-center px-1 pt-1 text-sm font-medium";
  const activeLargeButton = "border-b-2 border-[var(--SecondaryColor)] text-[var(--PrimaryColor)] font-semibold";
  const inactiveLargeButton = "border-b-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700";

  const baseSmallButton = 'block border-l-4 py-2 pl-3 pr-4 text-base font-medium';
  const activeSmallButton = 'border-[var(--PrimaryColor)] bg-[var(--PrimaryBkg)] text-white';
  const inactiveSmallButton = 'border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800';

  if (userInfo == null) {
    return;
  }
  return (
    <div>
      <Disclosure as="nav" className="bg-[var(--whiteColorDeam)] shadow-md">
        <div className="mx-auto w-full px-2 sm:px-4 lg:px-8">
          <div className="flex h-14 justify-between">
            <div className="flex px-2 lg:px-0">
              <Link to={dashboardItem.url} className="flex flex-shrink-0 items-center" >
                <img
                  alt="JEETA Logo"
                  src={jeetaLogo}
                  className="h-[40px] w-[40px] mb-1"
                />
                <span className="hidden md:block ml-2 text-2xl font-bold tracking-tight text-[var(--PrimaryColor)]">
                  JEETA
                </span>
              </Link>
            </div>

            {/* Render menu items */}
            <div className="flex p-auto block flex-1 justify-center">

              <ul className="lg:ml-6 flex gap-2 md:gap-4 lg:space-x-8 justify-center">
                {navItems
                  .filter(item => item.title !== "dashboard" && item.title !== "profile")
                  .map((item, index) => (
                    <li
                      key={index}
                      className={`${baseLargeButton} ${location.pathname === item.url ? activeLargeButton : inactiveLargeButton
                        }`}
                    >
                      <Link to={item.url}>
                        <NavbarIconGenerator title={item.title} />
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>

            {/* SearchBar for the future */}
            {/* <div className="flex flex-1 items-center justify-center px-2 lg:ml-6 lg:justify-end">
            <div className="w-full max-w-lg lg:max-w-xs">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <MagnifyingGlassIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="search"
                  name="search"
                  type="search"
                  placeholder="Search"
                  className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div> */}

            <div className="flex items-center gap-1 md:gap-4 ml-4">
              <Menu as="div" className="relative">
                <MenuButton className="relative flex items-center rounded-full bg-white text-sm hover:text-[var(--PrimaryColor)] focus:outline-none focus:ring-2 focus:ring-[var(--SecondaryBkg)] focus:ring-offset-2">
                  <GlobeAltIcon className="h-8 w-8 text-[var(--SecondaryColor)]" />
                </MenuButton>
                <MenuItems
                  transition
                  className="absolute right-0 z-10 mt-2 w-20 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >  <MenuItem>
                    <button onClick={() => changeLanguage('en')} className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100">
                      English
                    </button>
                  </MenuItem>
                  <MenuItem>
                    <button onClick={() => changeLanguage('ko')} className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100">
                      한국어
                    </button>
                  </MenuItem>
                  {/* Add more languages here if needed */}
                </MenuItems>
              </Menu>
              {/* Mobile menu button */}
              <DisclosureButton className="group mx-1 my-3 relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-[var(--PrimaryBkg)] hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[var(--PrimaryColor)] block md:hidden">
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Open menu</span>
                <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
                <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
              </DisclosureButton>
              {/* <button
                type="button"
                className="hidden md:block relative flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-[var(--SecondaryBkg)] focus:ring-offset-2"
              >
                <span className="absolute -inset-1.5" />
                <span className="sr-only">View notifications</span>
                <BellIcon aria-hidden="true" className="h-6 w-6" />
              </button> */}

              {/* Profile dropdown */}
              <Menu as="div" className="hidden md:block relative flex-shrink-0">
                <div>
                  <MenuButton className="hidden md:block relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-[var(--SecondaryBkg)] focus:ring-offset-2">
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">Open profile</span>
                    {userInfo && userInfo.user_data.photo ? (
                      <img
                        alt="user-image"
                        src={userInfo.user_data.photo}
                        className="h-8 w-8 rounded-full"
                      />
                    ) : (
                      <UserCircleIcon className="h-8 w-8 text-[var(--SecondaryColor)]" onClick={toggleProfilePopup} />
                    )}
                    <ProfilePopup
                      profileItem={profileItem}
                      user={user}
                      isOpen={isProfilePopupOpen}
                      onRequestClose={() => setProfilePopupOpen(false)}
                    />
                  </MenuButton>
                </div>
                <MenuItems
                  transition
                  className="absolute right-0 z-10 mt-2 w-52 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <MenuItem>
                    <Link to={profileItem.url} className="flex text-center items-center gap-2 block px-4 py-2 text-md text-[var(--PrimaryColor)] data-[focus]:bg-gray-100">
                      <PiHandWaving className="h-5 w-5" />
                      {t('hi')} {userInfo.user_data.first_name} {t('formalNameSuffix')}
                    </Link>
                  </MenuItem>
                  {user === TEACHER_USER_TYPE && nextClassInfo.pin && (
                    <div className="flex justify-center">
                      <PINCode PIN={nextClassInfo.pin} />
                    </div>
                  )}
                  <MenuItem>
                    <Link to={profileItem.url} className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100">
                      {t('yourProfile')}
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <p onClick={() => { setOpenFeedback(true) }}
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
                    >
                      {t('feedback')}
                    </p>
                  </MenuItem>
                  <MenuItem>
                    <p onClick={onLogout} className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100">
                      {t('signOut')}
                    </p>
                  </MenuItem>
                </MenuItems>
              </Menu>
            </div>
          </div>
        </div>

        <DisclosurePanel className="md:hidden">
          {/* <div className="space-y-1 pb-3 pt-2">
            {navItems.filter(item => (item.title !== "Dashboard" && item.title !== "Profile")).map((item, index) => {
              <DisclosureButton
                as={Link}
                to={item.url}
                key={index}
                className={`${baseSmallButton} ${location.pathname === item.url ? activeSmallButton : inactiveSmallButton
                  }`}
              >
                {item.title}
              </DisclosureButton>
            })}
          </div> */}
          <div className="border-t border-gray-200 pb-3 pt-4">
            <div className="flex items-center px-4">
              <div className="flex-shrink-0">
                {userInfo && userInfo.user_data.photo ? (
                  <img
                    alt="user-image"
                    src={userInfo.user_data.photo}
                    className="h-10 w-10 rounded-full"
                  />
                ) : (
                  <UserCircleIcon className="h-10 w-10 text-[var(--SecondaryColor)]" onClick={toggleProfilePopup} />
                )}
              </div>
              <div className="ml-3">
                <div className="text-base font-medium text-gray-800">{userInfo.user_data.first_name}</div>
                <div className="text-sm font-medium text-gray-500">{userInfo.user_data.email}</div>
              </div>
              {user === TEACHER_USER_TYPE && nextClassInfo.pin && (
                <div className="flex justify-center">
                  <PINCode PIN={nextClassInfo.pin} />
                </div>
              )}
              {/* <button
                type="button"
                className="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-[var(--SecondaryBkg)] focus:ring-offset-2"
              >
                <span className="absolute -inset-1.5" />
                <span className="sr-only">View notifications</span>
                <BellIcon aria-hidden="true" className="h-6 w-6" />
              </button> */}
            </div>
            <div className="mt-3 space-y-1">
              <Link to={profileItem.url}>
                <DisclosureButton
                  className="block px-4 py-2 text-sm md:text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                >
                  {t('yourProfile')}
                </DisclosureButton>
              </Link>
              <DisclosureButton
                onClick={() => { setOpenFeedback(true) }}
                className="block px-4 py-2 text-sm md:text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
              >
                Feedback
              </DisclosureButton>
              <DisclosureButton
                onClick={onLogout}
                className="block px-4 py-2 text-sm md:text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
              >
                {t('signOut')}
              </DisclosureButton>
            </div>

          </div>
          {/* <div className="navbar flex">
      

      <div className="navBtns flex">

        <div className="leftBtns">
          <Link to={dashboardItem.url} className="logoDiv flex">
            <img src={jeetaLogo} className="companyLogo" alt="companyLogo" />
            {isIconVisible ? (<></>) : (<span className="companyTitle"> JEETA</span>)}
          </Link>
        </div>

        <div className="rightBtns flex">
          <FaUserCircle className="icon" id="account" onClick={toggleProfileModal} />

          <ProfilePopup
            profileItem={profileItem}
            user={user}
            isOpen={isProfilePopupOpen}
            onRequestClose={() => setProfilePopupOpen(false)}
          />

        </div>
      </div>

      {
        isIconVisible ? (
          <div className="menu-iconVisible">
            <ui className="navList flex">
              {navItems.filter(item => (item.title !== "Dashboard" && item.title !== "Profile")).map((item, index) => {
                return (
                  <li key={index} className="navItem">
                    <Link to={item.url} className={item.cName} id={`${location.pathname === item.url ? 'selected' : ''}`}>
                      <NavbarIconGenerator title={item.title} />
                    </Link>
                  </li>
                )
              })}
            </ui>

          </div>
        ) : (
          <div className="menu">
            <ui className="navList flex">
              {navItems.filter(item => (item.title !== "Dashboard" && item.title !== "Profile")).map((item, index) => {
                return (
                  <li key={index} className="navItem">
                    <Link to={item.url} className={item.cName} id={`${location.pathname === item.url ? 'selected' : ''}`}>
                      {item.title}
                    </Link>
                  </li>
                )
              })}
            </ui>

          </div>
        )
      }

    </div > */}

        </DisclosurePanel>
      </Disclosure >

      <Feedback
        user={user}
        isOpen={openFeedback}
        onRequestClose={onRequestFeedbackClose}
        onFeedbackSend={onFeedbackSend}
      />
    </div>



  )
}

export default Navbar