import React from 'react'

import bkgImage from '../../Assets/Analytics.png'

import Navbar from '../../Components/Navbar/Navbar';
import Hero from '../../Components/Hero/Hero';
import Footer from '../../Components/Footer/Footer';
import Analytics from '../../Components/Analysis/Analytics';

import { StudentNavItems } from './StudentNavItems';
import { STUDENT_USER_TYPE } from '../../services/UserService';

const StudentAnalytics = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <Navbar navItems={StudentNavItems} user={STUDENT_USER_TYPE} />
            <Analytics user={STUDENT_USER_TYPE} />

            <Footer />
        </div>
    )
};

export default StudentAnalytics;