import React from 'react'

import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer';
import BatchManage from '../../Components/Batches/BatchManage';

import { AdminNavItems } from './AdminNavItems';
import { ADMIN_USER_TYPE } from '../../services/UserService';

const AdminBatchesManage = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <Navbar navItems={AdminNavItems} />
            <BatchManage user={ADMIN_USER_TYPE} />
            <Footer />

        </div>
    )
}

export default AdminBatchesManage;
