import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PhysicsIcon from '../../../Assets/Physics.jpg';
import MathIcon from '../../../Assets/Physics.jpg';
import ScienceIcon from '../../../Assets/Physics.jpg';
import ChemistryIcon from '../../../Assets/Physics.jpg';
import BiologyIcon from '../../../Assets/Physics.jpg';
import DefaultIcon from '../../../Assets/Physics.jpg';

import { STUDENT_USER_TYPE } from "../../../services/UserService";
import { hasSlotPinViewAuthority } from '../../../services/TimetableService';
import PINCode from "../../Common/PINCode";
import { createParams } from '../../../services/CommonService';

const SlotCard = ({ slotData, slotStatus, showDates = false, user = STUDENT_USER_TYPE }) => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const goToSlotId = (slotId) => {
        let pathParams = { selectedTab: 'Video' };
        if (slotData.matching_segment_id) {
            pathParams['segmentId'] = slotData.matching_segment_id;
        }
        navigate(`/${user}/liveclass/slots/${slotId}${createParams(pathParams)}`);
    }

    const subjectIcons = {
        Physics: PhysicsIcon,
        Math: MathIcon,
        Science: ScienceIcon,
        Chemistry: ChemistryIcon,
        Biology: BiologyIcon,
    };

    const slotMatchingSegmentRefinedTitle = (slotData.matching_segment_refined_title == null ? null : slotData.matching_segment_refined_title);

    console.log(slotStatus);
    return (
        <div className="relative flex flex-1 flex-col p-8 rounded-xl overflow-hidden shadow-lg" onClick={() => goToSlotId(slotData.id)}>

            {/* <div className={`slot-card ${slotData.subject.subject_name}`} > */}

            {/* icon */}
            <img src={subjectIcons[slotData.subject.subject_name] || DefaultIcon}
                alt={`${slotData.subject.subject_name} Icon`}
                className="mx-auto size-32 contain shrink-0" />

            {/* Subject Name */}
            <h3 className="mt-6 text-sm font-medium text-gray-900">{t(slotData.subject.subject_name)}</h3>

            {/* background */}
            <div className={`absolute top-0 left-0 w-full h-full ${slotStatus === "CURRENT" ? "bg-white/30 z-0" : ""
                } ${slotStatus === "FINISHED" ? "bg-black/40 z-2" : ""
                }`}></div>

            <dl className="mt-1 flex grow flex-col justify-between">
                <dt className="sr-only">Time</dt>
                <dd className="text-sm text-gray-500">
                    {slotData.start_time.split('T')[1].slice(0, 5)}
                    -
                    {slotData.end_time.split('T')[1].slice(0, 5)}
                </dd>
                <dt className="sr-only">Teacher</dt>
                <dd className="text-sm text-gray-500">
                    {slotData.teacher.user_data.first_name} {slotData.teacher.user_data.last_name}
                </dd>
                <dt className="sr-only">Classroom</dt>
                <dd className="mt-3">
                    <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-green-600/20 ring-inset">
                        {slotData.lecture_hall ? slotData.lecture_hall.lecture_hall_name : t('online')}
                    </span>
                </dd>
            </dl>

            <div>
                <div className="-mt-px flex divide-x">
                    <div className="flex w-0 flex-1">
                        {showDates &&
                            <span
                                className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                            >
                                {slotData.start_time.split('T')[0].slice(8, 10)}
                                /
                                {slotData.start_time.split('T')[0].slice(5, 7)}
                                /
                                {slotData.start_time.split('T')[0].slice(2, 4)}
                            </span>
                        }
                    </div>
                    <div className="-ml-px flex w-0 flex-1">
                        {hasSlotPinViewAuthority(user) && slotData.pin && slotStatus !== "FINISHED" &&
                            <span
                                className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                            >
                                <PINCode PIN={slotData.pin} />
                            </span>
                        }
                    </div>
                </div>
            </div>

            {slotMatchingSegmentRefinedTitle !== null &&
                <span className="matchingSegment">{slotMatchingSegmentRefinedTitle}</span>
            }

        </div >
    )

}

export default SlotCard;