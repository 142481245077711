import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';

// import './QuestionCard.css'

import './Syllabus.css'

import SelectionDropdown from '../Common/SelectionDropdown';

import { ADMIN_USER_TYPE } from '../../services/UserService';
import { navigateCallbackOptions } from '../../services/AuthenticationService';
import { securedFetchSubjects, securedFetchTopicsByFilter } from '../../services/SyllabusService';
import QuestionCardList from '../Questions/QuestionView/QuestionCardList';
import Questions from '../Questions/Questions';


const SyllabusQuestions = ({ syllabusFilter, user = ADMIN_USER_TYPE }) => {

    const [questionSearchFilter, setQuestionSearchFilter] = useState(null);
    const navigate = useNavigate();

    const makeSearchFilter = () => {
        let selectedTopics = [];
        if (!syllabusFilter.topics || !syllabusFilter.mainTopics) {
            return {};
        }
        if (syllabusFilter.selectedTopics.length > 0) {
            selectedTopics = selectedTopics.concat(syllabusFilter.selectedTopics);
        }
        if (syllabusFilter.selectedMainTopics.length > 0) {
            const notAddedMainTopics = syllabusFilter.selectedMainTopics.filter(mainTopic => syllabusFilter.selectedTopics.find(topic => topic.parent_id === mainTopic.id) == null);
            selectedTopics = selectedTopics.concat(notAddedMainTopics);
        }
        return {
            subjects: syllabusFilter.subjects,
            selectedSubjects: syllabusFilter.selectedSubjects,
            grades: syllabusFilter.grades,
            selectedGrades: syllabusFilter.selectedGrades,
            topics: syllabusFilter.topics.concat(syllabusFilter.mainTopics),
            selectedTopics: selectedTopics
        }
    }

    useEffect(() => {
        setQuestionSearchFilter(makeSearchFilter());
    }, [syllabusFilter]);

    if (!questionSearchFilter) {
        return;
    }

    return (
        <div className="syllabusQuestionList flex">
            <Questions syllabusFilter={questionSearchFilter} hiddenFilters={{ topics: 'hide' }} user={user} />
        </div>
    );
};

export default SyllabusQuestions;