import React from 'react'

import image from '../../Assets/AdminProfile.png'

import Navbar from '../../Components/Navbar/Navbar';
import Hero from '../../Components/Hero/Hero';
import Footer from '../../Components/Footer/Footer';

import { AdminNavItems } from './AdminNavItems'
import { ADMIN_USER_TYPE } from '../../services/UserService';

import UserProfile from '../../Components/Profile/UserProfile';

const AdminProfile = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <Navbar navItems={AdminNavItems} />
            <UserProfile user={ADMIN_USER_TYPE} />

            <Footer />
        </div>
    )
};

export default AdminProfile;