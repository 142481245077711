import React, { useState, useEffect } from 'react';

import './Syllabus.css'

import { ADMIN_USER_TYPE } from '../../services/UserService';
import SlotCardList from '../Slots/SlotCard/SlotCardList';
import ReadingMaterialCardList from '../ReadingMaterial/ReadingMaterialCard/ReadingMaterialCardList';


const SyllabusReadingMaterials = ({ syllabusFilter, user = ADMIN_USER_TYPE }) => {

    const [readingMaterialSearchFilter, setReadingMaterialSearchFilter] = useState(null);
    const [page, setPage] = useState(1);

    const makeSearchFilter = () => {
        let selectedTopics = [];
        if (!syllabusFilter.topics || !syllabusFilter.mainTopics) {
            return {};
        }
        if (syllabusFilter.selectedTopics.length > 0) {
            selectedTopics = selectedTopics.concat(syllabusFilter.selectedTopics);
        } else if (syllabusFilter.selectedMainTopics.length > 0) {
            selectedTopics = selectedTopics.concat(syllabusFilter.selectedMainTopics);
        }
        return {
            subjects: syllabusFilter.subjects,
            selectedSubjects: syllabusFilter.selectedSubjects,
            grades: syllabusFilter.grades,
            selectedGrades: syllabusFilter.selectedGrades,
            topics: syllabusFilter.topics.concat(syllabusFilter.mainTopics),
            selectedTopics: selectedTopics
        }
    }

    useEffect(() => {
        setReadingMaterialSearchFilter(makeSearchFilter());
    }, [syllabusFilter]);

    if (!readingMaterialSearchFilter) {
        return;
    }

    return (
        <div className="syllabusReadingMaterialList flex">
            <ReadingMaterialCardList syllabusFilter={readingMaterialSearchFilter} user={user} page={page} setPage={setPage} />
        </div>
    );
};

export default SyllabusReadingMaterials;