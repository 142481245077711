import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import moment from 'moment';

import SlotCard from './SlotCard';
import SlotCardListFilter from './SlotCardListFilter';

import { AiOutlineLeft, AiOutlineRight, AiFillCloseCircle } from 'react-icons/ai'
import { securedFetchSlots } from '../../../services/BatchService';
import { ADMIN_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import Spinner from '../../Common/Tailwind/Spinner';


const SlotCardList = ({ syllabusFilter = null, showDates = false, user = ADMIN_USER_TYPE, page = 1, setPage }) => {

    const [slots, setSlots] = useState([]);
    const [totalSlotCount, setTotalSlotCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const makeFilter = (currentPage, externalFilter = null) => {
        const currentSearchFilter = externalFilter ? externalFilter : syllabusFilter;
        const filter = { skip: (currentPage - 1) * 12, limit: 12 }
        if (!currentSearchFilter) {
            return filter;
        }
        if (currentSearchFilter.selectedTopics && currentSearchFilter.selectedTopics.length > 0) {
            filter['topic_ids'] = currentSearchFilter.selectedTopics.map((item) => (item.id));
        }
        else if ((currentSearchFilter.selectedSubjects && currentSearchFilter.selectedSubjects.length > 0) ||
            (currentSearchFilter.selectedGrades && currentSearchFilter.selectedGrades.length > 0)) {
            filter['topic_ids'] = currentSearchFilter.topics.map((item) => (item.id));
        }
        if (currentSearchFilter.queryString && currentSearchFilter.queryString.length > 0) {
            filter['query_string'] = currentSearchFilter.queryString;
        }
        return filter;
    }

    const renderSlotCard = (slotData) => {
        const slotStatus = (slotData.start_time, slotData.end_time);
        return <SlotCard slotData={slotData} slotStatus={slotStatus} showDates={showDates} user={user} />;
    }

    const renderSlotCards = () => {
        return (
            <>
                {
                    slots.map(slotData => (
                        renderSlotCard(slotData)
                    ))
                }
            </>
        )
    }

    const fetchData = async (hasFilterChanged = false, externalFilter = null) => {
        let currentPage = page;
        if (hasFilterChanged) {
            setPage(1);
            currentPage = 1;
        }
        setLoading(true);
        const currentSearchFilter = externalFilter ? externalFilter : syllabusFilter;
        const response = await securedFetchSlots(makeFilter(currentPage, currentSearchFilter), navigateCallbackOptions(navigate));
        if (response === null) {
            return;
        }
        setTotalSlotCount(response.count);
        setSlots(response.data);
        setLoading(false);
    };

    const onPageChange = async (changeUnit) => {
        const numberOfPages = Math.ceil(totalSlotCount / 12);
        if (page + changeUnit < 1 || page + changeUnit > numberOfPages || changeUnit === 0) {
            return;
        }
        setPage(page + changeUnit);
        setLoading(true);
        const response = await securedFetchSlots(makeFilter(page + changeUnit), navigateCallbackOptions(navigate));
        if (response === null) {
            return;
        }
        setTotalSlotCount(response.count);
        setSlots(response.data);
        setLoading(false);
    }

    const displayPageButtons = () => {
        const numberOfButtonsAfterPage = Math.max(0, Math.ceil((totalSlotCount - page * 12) / 12));
        const startIndex = Math.max(page - 5, 1);
        const endIndex = Math.min(startIndex + 10, page + numberOfButtonsAfterPage);

        return Array.from({ length: endIndex - startIndex + 1 }, (_, index) => (

            <button button key={startIndex + index} className={startIndex + index === page ? "page-btns current-page-btn" : "page-btns not-current-page-btn"} onClick={() => onPageChange(startIndex + index - page)} >
                {startIndex + index}
            </button>

        ));
    }

    const onSubmitFilter = async (externalFilter = null) => {
        await fetchData(true, externalFilter);
    }

    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    }, []);

    const renderCardList = () => {
        return (
            <>
                <SlotCardListFilter onSubmitFilter={onSubmitFilter} syllabusFilter={syllabusFilter} user={user} />
                {/* searchFilter={searchFilter} setSearchFilter={setSearchFilter} onSubmitFilter={onSubmitFilter} user={user} allowedTypes={allowedTypes} hiddenFilters={hiddenFilters} refreshKey={refreshKey}  */}
                {loading ? <Spinner /> : (

                    <div className="slotCardList flex">

                        <div className="slotCards">
                            {slots && renderSlotCards()}
                        </div>

                        <div className="change-page-btns flex">
                            <button className="left-btn flex" onClick={async () => await onPageChange(-1)} hidden={page === 1}>
                                <AiOutlineLeft className="icon" />
                            </button>

                            {displayPageButtons(totalSlotCount)}

                            <button className="right-btn flex" onClick={async () => await onPageChange(1)}>
                                <AiOutlineRight className="icon" />
                            </button>
                        </div>
                    </div>)}

            </>)
    }

    return (
        <>

            {renderCardList()}
        </>
    );
};

export default SlotCardList;