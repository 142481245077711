import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';

import './testSection.css'

import numberToWords from 'number-to-words';

import QuestionCreate from '../../../Questions/QuestionCreate/QuestionCreate';
import QuestionCardList from '../../../Questions/QuestionView/QuestionCardList';

import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../../../services/UserService';
import { navigateCallbackOptions } from '../../../../services/AuthenticationService';
import { canCreateTestSection, canShowSectionMarks, securedAddTestQuestions, securedCreateTestSectionForTestId, securedDeleteTestQuestion, securedDeleteTestSection, securedDeleteTestSubject, securedFetchTestById, securedFetchTestSectionById, securedFetchTestSectionsByTestId, securedFetchTestSubjectById, securedUpdateTestSections, securedUpdateTestSubjects } from '../../../../services/TestService';
import { QUESTION_FILTER_DROPDOWN_TYPES, QUESTION_UPLOAD_TYPES, securedFetchQuestionById, securedFetchQuestions } from '../../../../services/QuestionService';
import { useTest } from '../../TestContext';
import TestQuestionView from '../../TestQuestion/TestQuestionView';

import { AiFillCloseCircle, AiFillEdit } from 'react-icons/ai';
import { IoMdCreate } from "react-icons/io";
import { BiMessageSquareAdd } from 'react-icons/bi';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import TestSectionMarkingSchemeRender from './TestSectionMarkingSchemeRender';
import TestSectionMarkingSchemeUpsert from './TestSectionMarkingSchemeUpsert';
import TestSectionUpsert from './TestSectionUpsert';
import Spinner from '../../../Common/Tailwind/Spinner';

const TestSectionRender = ({ testId, testStatus, user = ADMIN_USER_TYPE, subjects, testSection, testType }) => {

    const {
        testSubjects,
        setTestSubjects,
        testSections,
        setTestSections,
        testQuestions,
        setTestQuestions,
        testParagraphs,
        setTestParagraphs,
    } = useTest();

    const [testSectionDescription, setTestSectionDescription] = useState(null);
    const [questionChoosePage, setQuestionChoosePage] = useState(1);
    const [questionChooseSearchFilter, setQuestionChooseSearchFilter] = useState(null);
    const [questionChooseModalOpenSectionId, setQuestionChooseModalOpenSectionId] = useState(null);
    const [questionCreateFilter, setQuestionCreateFilter] = useState(null);
    const [questionCreateModalOpenSectionId, setQuestionCreateModalOpenSectionId] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [isHidden, setIsHidden] = useState(false);
    const [questionsForSection, setQuestionsForSection] = useState([]);
    const [paragraphsForSection, setParagraphsForSection] = useState([]);
    const [isEditTestSection, setEditTestSection] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const getAllowedTypes = () => {
        if (paragraphsForSection.length > 0) {
            return QUESTION_UPLOAD_TYPES.filter(type => type.id === 'paragraph');
        }
        if (questionsForSection.length > 0) {
            return QUESTION_UPLOAD_TYPES.filter(type => type.id === questionsForSection[0].question.question_subtype)
        }
        return QUESTION_UPLOAD_TYPES.filter(type => type.id !== 'subjective');
    }

    const getQuestionType = (questionData) => {
        if ((questionData.questions !== undefined) && (questionData.images || questionData.paragraph_text)) {
            return 'paragraph';
        }
        return 'standalone';
    }

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const toggleHidden = () => {
        setIsHidden(!isHidden);
    };

    const getNextQuestionOrder = (sectionId) => {
        const sectionQuestions = testQuestions.filter(testQuestion => testQuestion.test_section_id === sectionId);
        if (sectionQuestions.length === 0) {
            return 0;
        }
        return Math.max(...sectionQuestions.map(testQuestion => testQuestion.question_order)) + 1;
    }

    const getNextParagraphOrder = (sectionId) => {
        const sectionParagraphs = testParagraphs.filter(testParagraph => testParagraph.test_section_id === sectionId);
        if (sectionParagraphs.length === 0) {
            return 0;
        }
        return Math.max(...sectionParagraphs.map(testParagraph => testParagraph.paragraph_order)) + 1;
    }

    useEffect(() => {
        (async () => {
            if (testSection === null) {
                return;
            }
            setQuestionsForSection(testQuestions.filter(testQuestion => testQuestion.test_section_id === testSection.id).sort((testQuestion1, testQuestion2) => testQuestion1.question_order - testQuestion2.question_order));
            setParagraphsForSection(testParagraphs.filter(testParagraph => testParagraph.test_section_id === testSection.id).sort((testParagraph1, testParagraph2) => testParagraph1.paragraph_order - testParagraph2.paragraph_order));
        })();
    }, [testQuestions, testParagraphs, testSection]);

    const onAddQuestion = async (type, questionId) => {
        setIsLoading(true);
        const subjectId = testSections.find((testSection) => testSection.id === questionCreateModalOpenSectionId).test_subject_id;
        const newQuestion = await securedFetchQuestionById(type, questionId, { show_correct_answer: user !== STUDENT_USER_TYPE }, navigateCallbackOptions(navigate));
        if (type === 'standalone') {
            const questionOrder = getNextQuestionOrder(questionCreateModalOpenSectionId);
            const uploadedTestQuestion = await securedAddTestQuestions(type, testId, subjectId, questionCreateModalOpenSectionId,
                [{ question_id: newQuestion.id, question_order: questionOrder }]);
            const questionData = {
                id: uploadedTestQuestion[0].test_question_id, question_id: newQuestion.id, test_section_id: questionCreateModalOpenSectionId,
                question_order: questionOrder, question: newQuestion
            };
            setTestQuestions([...testQuestions, questionData]);
        }
        else if (type === 'paragraph') {
            const paragraphOrder = getNextParagraphOrder(questionCreateModalOpenSectionId)
            const uploadedTestParagraph = await securedAddTestQuestions(type, testId, subjectId, questionCreateModalOpenSectionId,
                [{ paragraph_id: newQuestion.id, paragraph_order: paragraphOrder }], navigateCallbackOptions(navigate));
            const paragraphData = {
                id: uploadedTestParagraph[0].test_paragraph_id, question_id: newQuestion.id, test_section_id: questionCreateModalOpenSectionId,
                paragraph_order: paragraphOrder, paragraph: newQuestion
            };
            setTestParagraphs([...testParagraphs, paragraphData]);
        }
        setIsLoading(false);
    }

    const onQuestionChooseOpen = (section) => {
        const testSubject = testSubjects.find(testSubject => testSubject.id === section.test_subject_id);
        const subject = subjects.find(subject => subject.id === testSubject.subject_id);
        let sectionType = QUESTION_UPLOAD_TYPES[0].id;
        const firstQuestionForSection = testQuestions.find(testQuestion => testQuestion.test_section_id === section.id);
        if (firstQuestionForSection) {
            sectionType = firstQuestionForSection.question.question_subtype;
        }
        const firstParagraphForSection = testParagraphs.find(testParagraph => testParagraph.test_section_id === section.id);
        if (firstParagraphForSection) {
            sectionType = 'paragraph';
        }
        setQuestionChooseSearchFilter({ selectedSubjects: [subject], selectedType: QUESTION_FILTER_DROPDOWN_TYPES.find(questionType => questionType.id === sectionType) });
        setQuestionChooseModalOpenSectionId(section.id);
    }

    const onQuestionCreateOpen = (section) => {
        const testSubject = testSubjects.find(testSubject => testSubject.id === section.test_subject_id);
        const subject = subjects.find(subject => subject.id === testSubject.subject_id);
        let sectionType = QUESTION_UPLOAD_TYPES[0].id;
        const firstQuestionForSection = testQuestions.find(testQuestion => testQuestion.test_section_id === section.id);
        if (firstQuestionForSection) {
            sectionType = firstQuestionForSection.question.question_subtype;
        }
        const firstParagraphForSection = testParagraphs.find(testParagraph => testParagraph.test_section_id === section.id);
        if (firstParagraphForSection) {
            sectionType = 'paragraph';
        }
        setQuestionCreateFilter({ selectedSubject: subject, selectedType: QUESTION_UPLOAD_TYPES.find(questionType => questionType.id === sectionType) });
        setQuestionCreateModalOpenSectionId(section.id);
    }

    const handleQuestionCardClick = async (questionData) => {
        const testSubjectId = testSections.find((testSection) => testSection.id === questionChooseModalOpenSectionId).test_subject_id;
        const questionType = getQuestionType(questionData);
        if (getQuestionType(questionData) === 'standalone') {
            await handleSingleQuestionCardClick(testSubjectId, questionData);
        }
        else {
            await handleParagraphCardClick(testSubjectId, questionData);
        }
        setQuestionChooseModalOpenSectionId(null);
    }

    const handleSingleQuestionCardClick = async (testSubjectId, questionData) => {
        setIsLoading(true);
        const hasQuestion = testQuestions.find((question) => (question.question_id === questionData.id));
        if (hasQuestion) {
            setIsLoading(false);
            alert('This question has already been added to this test');
            return;
        }
        const fetchedQuestionData = await securedFetchQuestionById('standalone', questionData.id, { show_correct_answer: user !== STUDENT_USER_TYPE }, navigateCallbackOptions(navigate));
        const questionOrder = getNextQuestionOrder(questionChooseModalOpenSectionId);
        const uploadedTestQuestions = await securedAddTestQuestions('standalone', testId, testSubjectId, questionChooseModalOpenSectionId,
            [{ question_id: fetchedQuestionData.id, question_order: questionOrder }], navigateCallbackOptions(navigate));
        setTestQuestions([...testQuestions, {
            id: uploadedTestQuestions[0].test_question_id, question_id: fetchedQuestionData.id, test_section_id: questionChooseModalOpenSectionId,
            question_order: questionOrder, question: fetchedQuestionData
        }]);
        setIsLoading(false);
    };

    const handleParagraphCardClick = async (testSubjectId, paragraphData) => {
        setIsLoading(true);
        const hasParagraph = testParagraphs.find((paragraph) => (paragraph.paragraph_id === paragraphData.id));
        if (hasParagraph) {
            setIsLoading(false);
            alert('This paragraph has already been added to this test!');
            return;
        }

        const paragraphOrder = getNextParagraphOrder(questionChooseModalOpenSectionId);
        const uploadedTestParagraphs = await securedAddTestQuestions('paragraph', testId, testSubjectId, questionChooseModalOpenSectionId,
            [{ paragraph_id: paragraphData.id, paragraph_order: paragraphOrder }], navigateCallbackOptions(navigate));
        const addedParagraphData = await securedFetchQuestionById('paragraph', paragraphData.id, { show_correct_answer: user !== STUDENT_USER_TYPE }, navigateCallbackOptions(navigate));
        setTestParagraphs([...testParagraphs, {
            id: uploadedTestParagraphs[0].test_paragraph_id, paragraph_id: addedParagraphData.id, test_section_id: questionChooseModalOpenSectionId,
            paragraph_order: paragraphOrder, paragraph: addedParagraphData
        }]);
        setIsLoading(false);
    };

    const renderQuestions = () => {
        if (paragraphsForSection.length > 0) {
            return (
                <TestQuestionView
                    testId={testId}
                    questions={questionsForSection}
                    paragraphs={paragraphsForSection}
                    testStatus={testStatus}
                    showImages={true}
                    showAnswers={true}
                    user={user} />
            )
        }
        if (questionsForSection.length > 0) {
            return (
                <TestQuestionView
                    testId={testId}
                    questions={questionsForSection}
                    paragraphs={paragraphsForSection}
                    testStatus={testStatus}
                    showImages={true}
                    showAnswers={true}
                    user={user} />
            )
        }
        return;
    }


    const disableTypes = () => {
        if (testType === 'live' || testType === 'wrap up') {
            return false;
        }
        return (questionsForSection.length + paragraphsForSection.length > 0)
    }

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <>
            {canShowSectionMarks(testType) && (
                <TestSectionMarkingSchemeRender testSection={testSection} questionsForSection={questionsForSection} paragraphsForSection={paragraphsForSection} user={user} />
            )}

            {testStatus === 'NOT STARTED' && (
                <div className="testQuestionAddBtns flex">
                    <button className="btn flex"
                        onClick={() => { onQuestionCreateOpen(testSection) }} >
                        Create New Question <IoMdCreate className="icon" />
                    </button>

                    <QuestionCreate isOpen={questionCreateModalOpenSectionId !== null}
                        sectionId={questionCreateModalOpenSectionId}
                        onRequestClose={() => setQuestionCreateModalOpenSectionId(null)}
                        initialSelectedSubject={questionCreateFilter && questionCreateFilter.selectedSubject && questionCreateFilter.selectedSubject.id ? questionCreateFilter.selectedSubject : null}
                        initialSelectedType={questionCreateFilter && questionCreateFilter.selectedType && questionCreateFilter.selectedType.id ? questionCreateFilter.selectedType : null}
                        onAddQuestion={onAddQuestion}
                        hiddenFilters={{ subjects: 'hide', types: disableTypes() ? 'disable' : null }}
                        user={user} />

                    <button className="btn flex"
                        onClick={() => { onQuestionChooseOpen(testSection) }} >
                        Select a Question <BiMessageSquareAdd className="icon" />
                    </button>

                    <QuestionCardList isModal={true}
                        isOpen={questionChooseModalOpenSectionId !== null}
                        sectionId={questionChooseModalOpenSectionId}
                        onQuestionsChange={() => { }}
                        includeTestQuestions={true}
                        page={questionChoosePage}
                        setPage={setQuestionChoosePage}
                        searchFilter={questionChooseSearchFilter}
                        setSearchFilter={setQuestionChooseSearchFilter}
                        onRequestClose={() => setQuestionChooseModalOpenSectionId(null)}
                        testId={testId}
                        handleQuestionCardClick={async (questionData) => await handleQuestionCardClick(questionData)}
                        hiddenFilters={{ subjects: 'hide', types: disableTypes() ? 'disable' : null }}
                        user={user} />
                </div>)}

            {renderQuestions()}

        </>
    )
}

export default TestSectionRender