import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'

import TeacherRegisterForm from './TeacherRegisterForm';
import StudentRegisterForm from './StudentRegisterForm';

import { ADMIN_USER_TYPE } from "../../services/UserService";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";

const MemberRegisterForm = ({ isOpen = false, onRequestClose = null, onMemberCreate = null, type, user = ADMIN_USER_TYPE }) => {

    const [isSubmitted, setSubmitted] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setSubmitted(false);
    }, [])

    const renderForm = () => {
        switch (type.id) {
            case 'teacher':
                return <TeacherRegisterForm onTeacherCreate={onMemberCreate}
                    setIsSubmitted={setSubmitted} />;

            default:
                return <StudentRegisterForm onStudentCreate={onMemberCreate}
                    setIsSubmitted={setSubmitted} />;
        }
    }

    return (
        <Dialog open={isOpen} onClose={onRequestClose} className="relative z-10">

            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                            <button
                                type="button"
                                onClick={onRequestClose}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-[var(--PrimaryColor)] focus:ring-offset-2"
                            >
                                <span className="sr-only">{t('closeCapitalized')}</span>
                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                            </button>
                        </div>

                        {isSubmitted ?
                            (
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                        <CheckIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            {type.id === 'teacher' ? t('newTeacher') : t('newStudent')}
                                        </DialogTitle>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                {type.id === 'teacher' ? t('newTeacherRegisteredSuffix') : t('newStudentRegisteredSuffix')}
                                            </p>
                                        </div>
                                    </div>
                                </div>)
                            : (
                                <div>
                                    {renderForm()}
                                </div>
                            )}

                        {/* <Modal overlayClassName="customModal" className='memberRegisterModal' isOpen={isOpen} onRequestClose={onRequestClose} > */}

                        {/* </Modal > */}

                    </DialogPanel>
                </div>
            </div>
        </Dialog>

    )
};

export default MemberRegisterForm;
