import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import SelectionDropdown from '../../Common/SelectionDropdown';
import SelectionDropdownMultiple from '../../Common/SelectionDropdownMultiple';

import { ADMIN_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import { hasGradeFetchAuthority, securedFetchGrades } from '../../../services/BatchService';
import { securedFetchSubjects, securedFetchTopicsByFilter } from '../../../services/SyllabusService';

import { BiFilterAlt } from 'react-icons/bi'
import { securedFetchTagsByFilter } from '../../../services/TagService';
import { hasRecordedVideoCreateAuthority } from '../../../services/VideoService';
import { TbLockAccess } from 'react-icons/tb';
import VideoRequests from '../../Requests/VideoRequests';
import TailwindButton from '../../Common/Tailwind/TailwindButton';
import VideoRequestsPopup from '../../Requests/VideoRequestsPopup';

const SlotCardListFilter = ({ onSubmitFilter = null, syllabusFilter = null, user = ADMIN_USER_TYPE }) => {

    // { searchFilter = null, setSearchFilter, onSubmitFilter, allowedTypes = null, , hiddenFilters = null, refreshKey = 0 }

    const [isNarrowScreen, setIsNarrowScreen] = useState(window.innerWidth < 780);
    const [queryString, setQueryString] = useState('');
    const [isAccessRequestsOpen, setAccessRequestsOpen] = useState(false);
    const { t } = useTranslation();

    const onFilterClick = async () => {
        const filter = {
            ...syllabusFilter,
            queryString
        }
        if (onSubmitFilter) {
            await onSubmitFilter(filter);
        }
    }

    const renderAccessRequests = () => {
        return <VideoRequests refresh={isAccessRequestsOpen} types={['class_lecture']} user={user} />
    }

    useEffect(() => {
        const handleResize = () => {
            setIsNarrowScreen(window.innerWidth < 780);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    return (
        <div className="slotCardListFilter flex">

            {/* <div className="filterTitle flex"> */}

            {/* <h3 className="title"> Dive Deep into Learning: </h3> */}

            {/* {user === ADMIN_USER_TYPE ?
                    <span>{t('exploreAndMaster')} {isNarrowScreen ? <br /> : ""}{t('yourLectureDatabase')}</span>
                    :
                    <span>{t('everyLectureWatchedBridges')} {isNarrowScreen ? <br /> : ""}{t('gapsInKnowledge')}</span>
                } */}

            {/* </div> */}
            <div className="filters">
                <div className="searchTextInput flex">
                    <label className="title">{t('text')}</label>
                    <input type="text" value={queryString} onChange={e => setQueryString(e.target.value)} placeholder={`${t('searchText')}...`} />
                </div>
            </div>
            <div className="btns flex">
                <button className="btn flex" id="filter" onClick={onFilterClick}>{t('filter')} <BiFilterAlt className="icon" /> </button>
                {!hasRecordedVideoCreateAuthority(user) ? (<></>) : (
                    <>
                        <span> {t('or')}</span>
                        <TailwindButton
                            text={t('checkRequests')}
                            iconComponent={TbLockAccess}
                            onClick={() => setAccessRequestsOpen(true)} />
                    </>
                )}
            </div>

            <VideoRequestsPopup isOpen={isAccessRequestsOpen} onRequestClose={() => setAccessRequestsOpen(false)} refresh={isAccessRequestsOpen} types={['class_lecture']} user={user} />
        </div>
    );
};

export default SlotCardListFilter;