import { PiStudent } from "react-icons/pi";
import { GiTeacher } from "react-icons/gi";

// Sidebar Items
const SidebarItems = [
    {
        icon: PiStudent,
        heading: 'student'
    },

    {
        icon: GiTeacher,
        heading: 'teacher'
    },
];

export default SidebarItems;