import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Tooltip } from 'react-tooltip'
import { useTranslation } from "react-i18next";


import './SlotTest.css';

import Break from '../../../Assets/Break.png';
import TestEdit from '../../Test/TestEdit';
import TestHeader from '../../Test/TestHeader';
import SlotTestCreate from './SlotTestCreate';
import MessagePage from "../../Common/Tailwind/Page/MessagePage";
import { ADMIN_USER_TYPE } from '../../../services/UserService';
import { TEST_CREATE_TYPE_BASIC, securedFetchTests } from '../../../services/TestService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import { UnauthorizedError } from '../../../services/Errors';

import { PlusCircleIcon } from "@heroicons/react/24/outline";

const SlotTestEdit = ({ lectureData, user = ADMIN_USER_TYPE, testType }) => {

    const [isTestModalOpen, setTestModalOpen] = useState(false);
    const [tests, setTests] = useState([]);
    const [selectedTest, setSelectedTest] = useState({});
    const [testStatus, setTestStatus] = useState(null);
    const [isTestReportModalOpen, setTestReportModalOpen] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const makeFilter = () => {
        const filter = {
            timetable_slot_ids: [lectureData.id],
            test_types: [testType]
        };
        return filter;
    };

    const fetchAllTests = async () => {
        const allTestsResponse = await securedFetchTests(TEST_CREATE_TYPE_BASIC, makeFilter(), navigateCallbackOptions(navigate));
        if (allTestsResponse === null) {
            return [];
        }
        return allTestsResponse.data;
    };

    const fetchAndSetAllTests = async (selectedTestId = null) => {
        const response = await fetchAllTests();
        setTests(response);
        if (!selectedTestId) {
            setSelectedTest({});
            return;
        }
        const test = response.find((singleTest) => (singleTest.id === selectedTestId));
        await onTestSelectionChange({ value: test.id, label: test.test_name });
    };

    useEffect(() => {
        (async () => {
            await fetchAndSetAllTests();
        })();
    }, [testType]);

    const onTestSelectionChange = async (selection) => {
        const testId = selection ? selection.value : null;
        if (testId) {
            setSelectedTest({ id: selection.value, test_name: selection.label });
        }
    };

    const onTestCreate = async (testId) => {
        await fetchAndSetAllTests(testId);
    }

    const renderSelectLiveQuiz = () => {
        if (lectureData.status === 'finished' && !tests.length) {
            return <MessagePage imgsrc={Break} title={t('noLiveQuiz')} message1={""} message2={""} message3={""} />
        }

        return (
            <div class="flex flex-col py-4 mt-4 overflow-x-auto overflow-y-auto md:justify-center dark:border-gray-700">

                <h3 class="text-xl font-semibold text-center text-[var(--SecondaryColor)] capitalize lg:text-2xl dark:text-white">
                    {t('liveQuiz')}
                </h3>

                <div class="flex justify-center items-center">
                    {tests.length > 0 && tests.map((test) => (
                        <button
                            key={test.id}
                            className={`h-12 px-8 py-2 -mb-px text-sm text-center bg-transparent border-b-2 sm:text-base whitespace-nowrap focus:outline-none
                                ${selectedTest.id === test.id ? 'text-blue-600 border-blue-500 dark:border-blue-400 dark:text-blue-300'
                                    : 'text-gray-700 border-gray-200 dark:text-white cursor-base dark:border-gray-700 dark:hover:border-gray-400 hover:border-gray-400'}`}
                            onClick={() => { setSelectedTest({ id: test.id, test_name: test.test_name }) }}>
                            {test.test_name}
                        </button>
                    ))}

                    {lectureData && lectureData.status !== 'finished' && (
                        <button
                            className={`h-12 px-8 py-2 -mb-px text-sm text-center bg-transparent border-b-2 sm:text-base whitespace-nowrap focus:outline-none
                                ${selectedTest.id === 0 ? 'text-blue-600 border-blue-500 dark:border-blue-400 dark:text-blue-300'
                                    : 'text-gray-700 border-gray-200 dark:text-white cursor-base dark:border-gray-700 dark:hover:border-gray-400 hover:border-gray-400'}`}
                            onClick={() => { setTestModalOpen(true); setSelectedTest({ id: 0, test_name: "new quiz" }) }}>
                            <PlusCircleIcon className="w-5 h-5" />
                        </button>
                    )}
                </div>

            </div >
        );
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col justify-center items-center gap-2 sm:gap-3 md:gap-6 p-2 sm:p-4 md:p-6 lg:p-8">

                {/* Select Live Quiz */}
                {renderSelectLiveQuiz()}
                {/* <div className="quizSelect flex"> */}
                {/* <SelectionDropdown
                        className="quizSelectionDropdown"
                        name=""
                        itemList={tests}
                        selectedItem={selectedTest}
                        onSelectionChange={onTestSelectionChange}
                        nameField='test_name'
                        valueField='id'
                        placeHolderText={"Select Live Quiz"} /> */}


                {/* <Tooltip id={`tooltip-${lectureData.id}`} /> */}
                {/* </div> */}

                {/* Create New Live Quiz */}
                <SlotTestCreate isOpen={isTestModalOpen} onRequestClose={() => (setTestModalOpen(false))} lectureData={lectureData} onTestCreate={onTestCreate} user={user} testType={testType} />


                {/* Show Live Quiz ... */}
                <div className="quizDiv flex">
                    {selectedTest.id && (
                        <>
                            <TestHeader testData={tests.find(test => test.id === selectedTest.id)} testStatus={testStatus} setTestStatus={setTestStatus} user={user} />
                            {/* Test Form */}
                            <TestEdit id={selectedTest.id} testStatus={testStatus} user={user} testType={testType} />
                        </>
                    )}
                </div>
            </div>

        </div >
    );
}

export default SlotTestEdit;
