import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { DialogTitle } from '@headlessui/react'

import BaseRegisterForm from '../Authentication/BaseRegisterForm';
import GradeBatchFilter from '../Batches/GradeBatchFilter';
import { ADMIN_USER_TYPE, securedRegisterUser } from "../../services/UserService";
import { navigateCallbackOptions } from "../../services/AuthenticationService";
import { CloudArrowUpIcon, UserPlusIcon } from "@heroicons/react/24/outline";

const StudentRegisterForm = ({ onStudentCreate = null, setIsSubmitted, user = ADMIN_USER_TYPE }) => {

    const [email, setEmail] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [fullName, setFullName] = useState("");
    const [grades, setGrades] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState({});
    const [batches, setBatches] = useState([]);
    const [selectedBatch, setSelectedBatch] = useState({});
    const navigate = useNavigate();
    const { t } = useTranslation();

    const resetState = () => {
        setEmail("");
        setContactNumber("");
        setFullName("");
    }

    const onSelectedBatchChange = (selected) => {
        setSelectedBatch({ id: selected.value, batch_name: selected.label });
    }

    const handleRegister = async () => {
        setIsSubmitted(true);

        const data = {
            user_data: {
                first_name: fullName,
                last_name: "",
                email: email,
                phone_number: contactNumber,
                password: 'password'
            },
            batches: [{ batch_id: parseInt(selectedBatch.id) }]
        }
        const response = await securedRegisterUser('student', data, navigateCallbackOptions(navigate));
        if (response === null) {
            setIsSubmitted(false);
            return;
        }
        if (onStudentCreate) {
            await onStudentCreate();
        }
        resetState();
        setIsSubmitted(false);
    };

    return (

        <div>
            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                <UserPlusIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
            </div>

            <div className="mt-3 text-center sm:mt-5">
                <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                    {t('registerNewStudent')}
                </DialogTitle>

                <div className="mt-10 mb-4 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-4"
                        onSubmit={(event) => (event.preventDefault())}>

                        <BaseRegisterForm
                            email={email} setEmail={setEmail}
                            contactNumber={contactNumber} setContactNumber={setContactNumber}
                            fullName={fullName} setFullName={setFullName} />

                        <GradeBatchFilter
                            grades={grades} selectedGrade={selectedGrade}
                            batches={batches} selectedBatch={selectedBatch}
                            setGrades={setGrades} setSelectedGrade={setSelectedGrade}
                            setBatches={setBatches} setSelectedBatch={setSelectedBatch}
                            onSelectedBatchChange={onSelectedBatchChange}
                            required={true} />

                        <button className="flex w-full justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[var(--PrimaryColor)]"
                            onClick={handleRegister}>
                            {t('submitCapitalized')}
                            <CloudArrowUpIcon className="w-5 h-5" />
                        </button>
                    </form>
                </div>
            </div>
        </div >
    )
};

export default StudentRegisterForm;
