import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'

import { ADMIN_USER_TYPE, securedFetchUsers } from '../../services/UserService';
import { navigateCallbackOptions, returnJsonResponse } from '../../services/AuthenticationService';
import { hasSlotDeleteAuthority, hasSlotTestEditAuthority, hasSlotEditAuthority } from '../../services/TestService';
import { securedDeleteSlotById, securedFetchTimetableSlotById, securedUpdateSlotById, securedFetchLectureHalls, securedDeleteScheduleById, securedUpdateScheduleById, hasSlotPinViewAuthority, needsSlotPinAuthentication, securedFetchSlotStatus, securedDeleteSlotUsers, securedCreateSlotUsers, securedFetchTimetableStudentAttendance, ONLINE_CLASS_TYPE } from '../../services/TimetableService';
import { securedFetchBatchesByFilter } from '../../services/BatchService';
import { checkArraysEqual } from '../../services/CommonService';

import { securedFetchTopicsByFilter } from '../../services/SyllabusService';
import Spinner from '../Common/Tailwind/Spinner';
import * as HeroIcons from '@heroicons/react/24/outline';
import { PencilIcon, TrashIcon, XMarkIcon, ArrowUturnLeftIcon, CloudArrowUpIcon } from '@heroicons/react/24/solid'; // Make sure you're importing from the correct path
import Dropdown from '../Common/Tailwind/SelectMenus/Dropdown';

const SlotInfo = ({ lectureData, setLectureData, slotId,
    isAuthorizedToView, handleViewAuth, user = ADMIN_USER_TYPE }) => {

    const [teachers, setTeachers] = useState([]);
    const [selectedTeacher, setSelectedTeacher] = useState(null);
    const [halls, setHalls] = useState([]);
    const [selectedHall, setSelectedHall] = useState(null);
    const [slotDate, setSlotDate] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [editDate, setEditDate] = useState(false);
    const [modifyType, setModifyType] = useState(null);
    const [showModifyPopup, setShowModifyPopup] = useState(false);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [selectedRecurringOption, setSelectedRecurringOption] = useState("thisEvent");
    const [startScheduleDate, setStartScheduleDate] = useState(null);
    const [endScheduleDate, setEndScheduleDate] = useState(null);
    const [batches, setBatches] = useState([]);
    const [selectedBatches, setSelectedBatches] = useState([]);
    const [students, setStudents] = useState([]);
    const [topics, setTopics] = useState([]);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const fetchTeachers = async (filter) => {
        const teachersResponse = await securedFetchUsers('teachers', filter, navigateCallbackOptions(navigate));
        if (teachersResponse === null) {
            return null;
        }
        return teachersResponse.data.map(teacher => ({ id: teacher.id, teacher_name: teacher.user_data.first_name + ' ' + teacher.user_data.last_name }));
    }

    const fetchHalls = async (filter) => {
        const hallsResponse = await securedFetchLectureHalls(filter, navigateCallbackOptions(navigate))
        if (hallsResponse === null) {
            return null;
        }
        return hallsResponse.data;
    }

    useEffect(() => {
        (async () => {
            if (!hasSlotEditAuthority(user) || !lectureData) {
                return;
            }

        })();
    }, [lectureData]);

    useEffect(() => {
        (async () => {
            if (modifyType !== 'edit') {
                return;
            }
            if (teachers.length === 0) {
                const teachersResponse = await fetchTeachers({ subject_ids: [lectureData.subject_id] });
                setTeachers(teachersResponse);
            }
            if (halls.length === 0) {
                const hallsResponse = await fetchHalls(null);
                setHalls(hallsResponse);
            }
        })();
    }, [modifyType]);

    const initialize = async () => {
        const isAuthorized = await handleViewAuth();
        if (!hasSlotEditAuthority(user)) {
            return { auth: isAuthorized };
        }
        const batchesResponse = await securedFetchBatchesByFilter({}, navigateCallbackOptions(navigate));
        if (batchesResponse === null) {
            return null;
        }
        setBatches(batchesResponse.data);
        const usersResponse = await securedFetchUsers('students', {}, navigateCallbackOptions(navigate));
        if (usersResponse === null) {
            return null;
        }
        const allStudents = usersResponse.data.map(user => ({ name: `${user.user_data.first_name} ${user.user_data.last_name}`, id: user.id }));
        setStudents(allStudents);

        return { auth: isAuthorized, data: { batches: batchesResponse.data, students: allStudents } };
    }

    const refreshData = async (initialData = null) => {
        const slotResponse = await securedFetchTimetableSlotById(slotId, navigateCallbackOptions(navigate));
        if (slotResponse === null) {
            return;
        }
        if (slotResponse.status === 404) {
            alert('This slot does not exist!');
            navigate(-1);
            return;
        }
        let currentSlotStatus = lectureData ? lectureData.status : null;
        if (currentSlotStatus !== 'finished') {
            const slotStatus = await securedFetchSlotStatus(slotId, navigateCallbackOptions(navigate));
            if (slotStatus === null) {
                return;
            }
            if (slotStatus.status === 404) {
                alert("This slot's status was not found!");
                navigate(-1);
                return;
            }
            currentSlotStatus = slotStatus.status;
        }
        const lectureDataWithStatus = { ...slotResponse, status: currentSlotStatus };

        setStartScheduleDate(slotResponse.timetable_schedule.start_date);
        setEndScheduleDate(slotResponse.timetable_schedule.end_date);

        if (hasSlotEditAuthority) {
            const allBatchesData = initialData && initialData.batches ? initialData.batches : batches;
            const currentBatchIds = slotResponse.batches.map(batch => batch.batch_id);
            setSelectedBatches(allBatchesData.filter(batch => currentBatchIds.includes(batch.id)));

            const allStudentsData = initialData && initialData.students ? initialData.students : students;
            const currentStudentIds = slotResponse.users.map(user => user.user_id);
            setSelectedStudents(allStudentsData.filter(student => currentStudentIds.includes(student.id)));
        }
        let allTopics = [];
        if (slotResponse.topics && slotResponse.topics.length > 0) {
            allTopics = await securedFetchTopicsByFilter({ subject_ids: [slotResponse.subject_id] }, navigateCallbackOptions(navigate));
        }
        if (allTopics === null) {
            return;
        }
        setTopics(allTopics.data);
        setLectureData(lectureDataWithStatus);
    }

    const onChange = (setters) => (event) => {
        setters.forEach(setter => setter(event.target.value));
    }

    const checkIsNotFinishedSlot = () => {
        return !lectureData || lectureData.status !== 'finished';
    }

    const isSlotEditable = () => {
        if (lectureData.status === 'finished') {
            return false;
        }

        const currentTime = moment();
        const slotStartTime = moment(lectureData.start_time);

        return slotStartTime.isAfter(currentTime);
    };

    const isScheculeEditable = () => {

        const currentDate = moment().format('YYYY-MM-DD');
        const slotScheduleEndDate = moment(lectureData.timetable_schedule.end_date).format('YYYY-MM-DD');

        return currentDate <= slotScheduleEndDate;
    };

    const getLectureInfo = (lectureData, user) => {
        const isEditable = modifyType === 'edit' && hasSlotTestEditAuthority(user) && checkIsNotFinishedSlot();

        const baseInfo = [
            {
                label: "Subject",
                value: lectureData.subject.subject_name,
                icon: "AcademicCapIcon",
                editable: false
            },
            {
                label: "Teacher",
                value: lectureData.teacher.user_data.first_name,
                icon: "UserIcon",
                editable: isEditable,
                editComponent: (
                    <Dropdown
                        list={teachers}
                        selected={selectedTeacher}
                        onSelectionChange={onSelectedTeacherChange}
                        valueField={'id'}
                        nameField={'teacher_name'}
                        className="max-w-64"
                    />
                )
            },
            {
                label: "Date",
                value: convertDateTimeToDate(lectureData.start_time),
                icon: "CalendarIcon",
                editable: isEditable,
                editComponent: (
                    <input
                        className="mt-1 block w-full max-w-64 rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        type="date"
                        id="slotDate"
                        name="Date"
                        value={slotDate}
                        onChange={onChange([setSlotDate])}
                        required
                    />
                )
            },
            {
                label: "Start Time",
                value: convertDateTimeToTime(lectureData.start_time),
                icon: "ClockIcon",
                editable: isEditable,
                editComponent: (
                    <input
                        className="mt-1 block w-full max-w-64 rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        type="time"
                        id="startTime"
                        name="startTime"
                        value={startTime}
                        min="08:00"
                        max="24:00"
                        onChange={onChange([setStartTime])}
                        required
                    />
                )
            },
            {
                label: "End Time",
                value: convertDateTimeToTime(lectureData.end_time),
                icon: "ClockIcon",
                editable: isEditable,
                editComponent: (
                    <input
                        className="mt-1 block w-full max-w-64 rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        type="time"
                        id="endTime"
                        name="endTime"
                        value={endTime}
                        min="08:00"
                        max="24:00"
                        onChange={onChange([setEndTime])}
                        required
                    />
                )
            },
        ];

        if (lectureData.class_type !== ONLINE_CLASS_TYPE) {
            const hallName = lectureData.lecture_hall?.lecture_hall_name || t('online');

            baseInfo.splice(2, 0, {
                label: "Lecture Hall",
                value: hallName,
                icon: "BuildingOfficeIcon",
                editable: isEditable,
                editComponent: (
                    <Dropdown
                        list={halls}
                        selected={selectedHall}
                        onSelectionChange={onSelectedHallChange}
                        valueField={'id'}
                        nameField={'lecture_hall_name'}
                        className="max-w-64"
                    />
                )
            });
        }

        if (hasSlotEditAuthority(user)) {
            baseInfo.push(
                {
                    label: "Batches",
                    value: makeBatchString(),
                    icon: "UserGroupIcon",
                    editable: isEditable,
                    editComponent: (
                        <Dropdown
                            list={batches}
                            selected={selectedBatches}
                            onSelectionChange={onSelectedBatchToggle}
                            nameField={'batch_name'}
                            valueField={'id'}
                            multiple={true}
                            isSearchable={true}
                            className="max-w-64"
                        />
                    )
                },
                {
                    label: "Students",
                    value: makeStudentsString(),
                    icon: "UsersIcon",
                    editable: isEditable,
                    editComponent: (
                        <Dropdown
                            list={students}
                            selected={selectedStudents}
                            onSelectionChange={onSelectedStudentToggle}
                            nameField={'name'}
                            valueField={'id'}
                            multiple={true}
                            isSearchable={true}
                            className="max-w-64"
                        />
                    )
                }
            );
        }

        // Add topic information if authorized and available
        if (isAuthorizedToView && lectureData.topics && lectureData.topics.length > 0) {
            baseInfo.push({
                label: "Lecture Topic",
                value: makeTopicsString(lectureData.topics),
                icon: "BookOpenIcon",
                editable: false
            });
        }

        return baseInfo;
    };

    const makeChangeDateData = () => {
        const changeData = {};
        if (startScheduleDate !== lectureData.timetable_schedule.start_date) {
            changeData['start_date'] = startScheduleDate;
        }
        if (endScheduleDate !== lectureData.timetable_schedule.end_date) {
            changeData['end_date'] = endScheduleDate;
        }
        return changeData;
    }

    const makeScheduleEditData = () => {
        const data = {};

        if (startTime !== lectureData.timetable_schedule.start_time) {
            data['start_time'] = startTime
        }
        if (endTime !== lectureData.timetable_schedule.end_time) {
            data['end_time'] = endTime;
        }
        if (selectedTeacher.id !== lectureData.timetable_schedule.teacher_id) {
            data['teacher_id'] = selectedTeacher.id;
        }
        if (lectureData.class_type !== ONLINE_CLASS_TYPE && selectedHall.id !== lectureData.timetable_schedule.lecture_hall_id) {
            data['lecture_hall_id'] = selectedHall.id;
        }
        if (!checkArraysEqual(selectedBatches.map(batch => batch.id), lectureData.timetable_schedule.batches.map(batch => batch.batch_id))) {
            data['batches'] = selectedBatches.map(batch => ({ batch_id: batch.id }));
        }

        data['effect_timestamp'] = lectureData.start_time;
        return data;
    }

    const makeSlotEditData = () => {
        const data = {};
        const startDateTime = `${slotDate}T${startTime}:00`;
        const endDateTime = `${slotDate}T${endTime}:00`;
        if (startDateTime !== lectureData.start_time) {
            data['start_time'] = startDateTime
        }
        if (endDateTime !== lectureData.end_time) {
            data['end_time'] = endDateTime;
        }
        if (selectedTeacher.id !== lectureData.teacher_id) {
            data['teacher_id'] = selectedTeacher.id;
        }
        if (lectureData.class_type !== ONLINE_CLASS_TYPE && selectedHall.id !== lectureData.lecture_hall_id) {
            data['lecture_hall_id'] = selectedHall.id;
        }
        if (!checkArraysEqual(selectedBatches.map(batch => batch.id), lectureData.batches.map(batch => batch.batch_id))) {
            data['batches'] = selectedBatches.map(batch => ({ batch_id: batch.id }));
        }

        return data;
    }

    const makeBatchString = () => {
        const lectureBatchIds = lectureData.batches.map(batch => (batch.batch_id));
        return batches.filter(batch => lectureBatchIds.includes(batch.id)).map(batch => batch.batch_name).join(', ');
    }

    const makeStudentsString = () => {
        const lectureUserIds = lectureData.users.map(user => (user.user_id));
        return students.filter(student => lectureUserIds.includes(student.id)).map(student => student.name).join(', ');
    }

    const makeTopicsString = () => {
        const lectureTopicIds = lectureData.topics.map(topic => (topic.topic_id));
        return topics.filter(topic => lectureTopicIds.includes(topic.id)).map(topic => topic.topic_name).join(', ');
    }

    function convertDateTimeToDate(dateTimeString) {
        return moment(dateTimeString).format('DD/MM/YYYY');
    }

    function convertDateTimeToTime(dateTimeString) {
        return moment(dateTimeString).format('h:mm A');
    }

    const onEditSubmit = async () => {
        let editData = null;
        if (selectedRecurringOption === "thisEvent") {
            editData = makeSlotEditData();
            if (Object.keys(editData).length > 0) {
                const response = await securedUpdateSlotById(slotId, editData, navigateCallbackOptions(navigate));
                if (response === null) {
                    return;
                }
            }
            const usersUpdateSuccess = await updateSlotUsers(slotId);
            if (Object.keys(editData).length === 0 && !usersUpdateSuccess) {
                setModifyType(null);
                return;
            }

        } else {
            editData = makeScheduleEditData();
            if (Object.keys(editData).length > 0) {
                const response = await securedUpdateScheduleById(lectureData.timetable_schedule_id, editData, navigateCallbackOptions(navigate));
                if (response === null) {
                    return;
                }
            } else {
                setModifyType(null);
                return;
            }
        }
        setModifyType(null);
        setShowModifyPopup(false);
        await refreshData();
    }

    const onEditDate = async (event) => {
        const changeData = makeChangeDateData();
        if (Object.keys(changeData).length === 0) {
            setEditDate(false);
            return;
        }
        const response = await securedUpdateScheduleById(lectureData.timetable_schedule_id, changeData, navigateCallbackOptions(navigate));
        if (response === null) {
            return;
        }
        setEditDate(false);
        await refreshData();
    }

    const onSelectedTeacherChange = async (selection) => {
        const teacherId = selection ? selection.value : null;
        if (teacherId) {
            setSelectedTeacher({ id: teacherId, teacher_name: selection.label });
        }
    };

    const onSelectedHallChange = async (selection) => {
        const hallId = selection ? selection.value : null;
        if (hallId) {
            setSelectedHall({ id: hallId, lecture_hall_name: selection.label });
        }
    };

    const onSelectedBatchToggle = async (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const newBatches = batches.filter(batch => selectedIds.includes(batch.id));
        setSelectedBatches(newBatches);
    };

    const onSelectedStudentToggle = async (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const newStudents = students.filter(student => selectedIds.includes(student.id));
        setSelectedStudents(newStudents);
    };

    const onEditClick = () => {
        setSlotDate(moment(lectureData.start_time).format('YYYY-MM-DD'));
        setStartTime(moment(lectureData.start_time).format('HH:mm'));
        setEndTime(moment(lectureData.end_time).format('HH:mm'));
        setSelectedTeacher({ id: lectureData.teacher_id, teacher_name: lectureData.teacher.user_data.first_name });
        if (lectureData.class_type !== ONLINE_CLASS_TYPE) {
            setSelectedHall({ id: lectureData.lecture_hall_id, lecture_hall_name: lectureData.lecture_hall?.lecture_hall_name || '' });
        }
        setModifyType('edit');
    }

    const onDelete = async () => {
        if (selectedRecurringOption === "thisEvent") {
            const response = await securedDeleteSlotById(slotId, navigateCallbackOptions(navigate));
            if (response === null) {
                return;
            }
        } else {
            const response = await securedDeleteScheduleById(lectureData.timetable_schedule_id, { effect_timestamp: lectureData.start_time }, navigateCallbackOptions(navigate));
            if (response === null) {
                return;
            }
        }
        setShowModifyPopup(false);
        navigate(-1);
    }

    const updateSlotUsers = async (slotId) => {
        if (checkArraysEqual(selectedStudents.map(student => student.id), lectureData.users.map(user => user.user_id))) {
            return true;
        }
        const newUserIds = selectedStudents.map(student => student.id);
        const oldUserIds = lectureData.users.map(user => user.user_id);
        const usersToAdd = newUserIds.map(newId => ({ user_id: newId }));
        if (oldUserIds.length > 0) {
            const deleteResponse = await securedDeleteSlotUsers(slotId, { user_ids: oldUserIds }, navigateCallbackOptions(navigate));
            if (deleteResponse === null) {
                return false;
            }
        }
        if (newUserIds.length > 0) {
            const updateResponse = await securedCreateSlotUsers(slotId, { timetable_slot_users: usersToAdd }, navigateCallbackOptions(navigate));
            if (updateResponse === null) {
                return false;
            }
        }
        return true;
    }

    const canEditSchedule = () => {
        if (slotDate !== lectureData.start_time.split('T')[0]) {
            return false;
        }
        const newUserIds = selectedStudents.map(student => student.id);
        const oldUserIds = lectureData.users.map(user => user.user_id);
        if (!checkArraysEqual(newUserIds, oldUserIds)) {
            return false;
        }
        return true;
    }

    const handleRadioChange = (event) => {
        setSelectedRecurringOption(event.target.value);
    };


    const LectureInfoCard = ({ info }) => {

        const IconComponent = HeroIcons[info.icon];

        return (
            <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                <div className="flex w-full max-w-80 items-center justify-between space-x-6 p-6">
                    <div className="flex-1 overflow-hidden">
                        <div className="flex items-center space-x-3">
                            <h3 className="truncate text-sm font-medium text-[var(--SecondaryColor)]">{info.label}</h3>
                        </div>
                        {modifyType === 'edit' && info.editable ? (
                            info.editComponent
                        ) : (
                            <p className="mt-1 truncate text-base font-semibold text-[var(--textColor)]">{info.value}</p>
                        )}
                    </div>
                    <IconComponent aria-hidden="true" className="h-8 w-8 text-[var(--PrimaryBkg)]" />
                </div>
            </li >
        );
    };

    const LectureSchedule = () => {
        return (
            <div className="my-2 md:my-4 lg:my-6 p-4 flex flex-col md:flex-row w-full md:max-w-4xl justify-center items-center rounded-lg bg-white shadow gap-2 sm:gap-3 md:gap-6">
                <div className="font-semibold tracking-tight lg:col-span-7">
                    <h3 className="inline sm:block lg:inline xl:block text-[var(--textColor)] text-base md:text-xl">LECTURE SCHEDULE</h3>
                </div>

                <div className="flex flex-col sm:flex-row gap-1 sm:gap-3 md:gap-6 lg:col-span-5">
                    <div className="flex justify-between md:justify-center items-center gap-x-2">
                        <label className="text-sm font-medium text-[var(--SecondaryColor)]">Start Date</label>
                        {editDate && hasSlotTestEditAuthority(user) && checkIsNotFinishedSlot() ? (
                            <input
                                className="min-w-0 max-w-40 flex text-sm rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                type="date"
                                id="startScheduleDate"
                                name="Schedule Start Date"
                                value={startScheduleDate}
                                onChange={onChange([setStartScheduleDate])}
                                required
                            />
                        ) : (
                            <p className="truncate text-base font-semibold text-gray-500">
                                {convertDateTimeToDate(lectureData.timetable_schedule.start_date)}
                            </p>
                        )}
                    </div>

                    <div className="flex justify-between md:justify-center items-center gap-x-2">
                        <label className="text-sm font-medium text-[var(--SecondaryColor)]">End Date</label>
                        {editDate && hasSlotTestEditAuthority(user) && checkIsNotFinishedSlot() ? (
                            <input
                                className="min-w-0 max-w-40 flex text-sm rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                type="date"
                                id="endScheduleDate"
                                name="Schedule End Date"
                                value={endScheduleDate}
                                onChange={onChange([setEndScheduleDate])}
                                required
                            />
                        ) : (
                            <p className="truncate text-base font-semibold text-gray-500">
                                {convertDateTimeToDate(lectureData.timetable_schedule.end_date)}
                            </p>
                        )}
                    </div>
                </div>

                {hasSlotEditAuthority(user) && isScheculeEditable() && checkIsNotFinishedSlot() && (
                    editDate ? (
                        <div className='flex justify-center items-center gap-2'>
                            <button
                                className="flex rounded-md space-x-2 md:space-x-0 text-white p-2 text-sm font-semibold bg-[var(--PrimaryColor)] hover:bg-[var(--SecondaryColor)]"
                                onClick={onEditDate}>
                                <span className="text-smblock md:hidden">SAVE</span>
                                <CloudArrowUpIcon aria-hidden="true" className="h-5 w-5" />
                            </button>
                            <button
                                className="flex rounded-md space-x-2 md:space-x-0 text-white p-2 text-sm font-semibold bg-[var(--PrimaryColor)] hover:bg-[var(--SecondaryColor)]"
                                onClick={() => setEditDate(false)}>
                                <span className="text-sm block md:hidden">CANCEL</span>
                                <ArrowUturnLeftIcon aria-hidden="true" className="h-5 w-5" />
                            </button>
                        </div>
                    ) : (
                        <button
                            className="flex rounded-md space-x-2 md:space-x-0 text-white p-2 text-sm font-semibold bg-[var(--PrimaryColor)] hover:bg-[var(--SecondaryColor)]"
                            onClick={() => setEditDate(true)}>
                            <span className="text-sm block md:hidden">EDIT</span>
                            <PencilIcon aria-hidden="true" className="h-5 w-5" />
                        </button>
                    )
                )}
            </div>
        );
    };

    const renderEditLectureInfoPopup = () => {
        return (

            <Dialog open={showModifyPopup} onClose={() => { setShowModifyPopup(false); setModifyType(null); }} className="relative z-10">

                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

                        <DialogPanel
                            transition
                            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                <button
                                    type="button"
                                    onClick={() => { setShowModifyPopup(false); setModifyType(null); }}
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                                </button>
                            </div>

                            <div>
                                <div className="mt-3 text-center sm:mt-5">
                                    <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                        {modifyType === 'edit' ? 'Save Recurring Event' : 'Delete Recurring Event'}
                                    </DialogTitle>

                                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                                        <formn className="space-y-4">
                                            <div className="px-10 py-4 flex flex-col items-start gap-2">
                                                <label className="flex items-center gap-1">
                                                    <input
                                                        className="h-4 w-4 border-gray-300 text-[var(--PrimaryColor)] focus:ring-indigo-600"
                                                        type="radio"
                                                        value="thisEvent"
                                                        checked={selectedRecurringOption === 'thisEvent'}
                                                        onChange={handleRadioChange}
                                                    />
                                                    This Event
                                                </label>
                                                {((modifyType === 'edit' && canEditSchedule()) || (modifyType === 'delete')) && (
                                                    <label className="flex items-center gap-1">
                                                        <input
                                                            className="h-4 w-4 border-gray-300 text-[var(--PrimaryColor)] focus:ring-indigo-600"
                                                            type="radio"
                                                            value="thisEventAndFollow"
                                                            checked={selectedRecurringOption === 'thisEventAndFollow'}
                                                            onChange={handleRadioChange}
                                                        />
                                                        This Event and Follow
                                                    </label>)}
                                            </div>

                                            <div>
                                                {modifyType === 'edit' && hasSlotEditAuthority(user) ? (
                                                    <button
                                                        onClick={onEditSubmit}
                                                        className="flex mx-auto justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)] gap-2"
                                                    >
                                                        SAVE
                                                    </button>
                                                ) : (
                                                    <button
                                                        onClick={onDelete}
                                                        className="flex mx-auto justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)] gap-2"
                                                    >
                                                        DELETE
                                                    </button>
                                                )}
                                            </div>

                                        </formn>
                                    </div>
                                </div>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        );
    };

    if (!lectureData) {
        return <Spinner />;
    }

    const lectureInfo = getLectureInfo(lectureData, user);
    const topicInfo = lectureInfo.find(item => item.label === "Lecture Topic");
    const otherInfo = lectureInfo.filter(item => item.label !== "Lecture Topic");

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col justify-center items-center gap-2 sm:gap-3 md:gap-6 p-2 sm:p-4 md:p-6 lg:p-8">
                {/* Topic */}
                {topicInfo && (
                    <div className="mx-auto max-w-3xl text-center">
                        <p className="text-xl font-semibold leading-7 text-[var(--PrimaryColor)]">{topicInfo.label}</p>
                        <h2 className="mt-0 md:mt-2 text-base sm:text-lg md:text-xl lg:text-2xl font-semibold tracking-tight text-gray-500">{topicInfo.value}</h2>
                    </div>
                )}

                {/* Info */}
                <ul role="list" className="grid grid-cols-1 gap-1 sm:gap-3 md:gap-6 sm:grid-cols-2 lg:grid-cols-3 pt-2 md:pt-8">
                    {otherInfo.map((info) => (
                        <LectureInfoCard
                            key={info.label}
                            info={info}
                            modifyType={modifyType}
                            hasSlotEditAuthority={hasSlotEditAuthority}
                        />
                    ))}
                </ul>

                {/* EDIT or DELETE SLOT for Admin ONLY */}
                {hasSlotDeleteAuthority(user) && isSlotEditable() &&
                    <div className="flex justify-center items-center p-4 gap-4">
                        {modifyType === null &&
                            <div className="flex gap-4">
                                <button className="flex w-full justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)]"
                                    onClick={onEditClick}>
                                    <span>EDIT</span>
                                    <PencilIcon className="w-4 h-4 text-white" />
                                </button>
                                <button className="flex w-full justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)]"
                                    onClick={() => { setShowModifyPopup(true); setModifyType('delete'); }}>
                                    <span>DELETE</span>
                                    <TrashIcon className="w-4 h-4 text-white" />
                                </button>
                            </div>
                        }
                        {modifyType === 'edit' &&
                            <div className="flex gap-4">
                                <button className="flex w-full justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)]"
                                    id="edit" onClick={() => setShowModifyPopup(true)}>
                                    <span>SAVE</span>
                                    <HeroIcons.CloudArrowUpIcon className="w-4 h-4 text-white" />
                                </button>
                                <button className="flex w-full justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)]"
                                    id="back" onClick={() => setModifyType(null)}>
                                    <span>CANCEL</span>
                                    <ArrowUturnLeftIcon className="w-4 h-4 text-white" />
                                </button>
                            </div>
                        }
                        {modifyType === 'delete' &&
                            <button className="btn flex" id="delete" onClick={() => { setShowModifyPopup(true); }}>
                                <span>DELETE</span>
                                <TrashIcon className="w-4 h-4 text-white" />
                            </button>
                        }
                    </div>
                }

                {renderEditLectureInfoPopup()}

                {/* Schedule */}
                {LectureSchedule()}
            </div>
        </div>
    );
}

export default SlotInfo;