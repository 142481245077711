import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from "react-i18next";

import './SlotTest.css';

import Break from '../../../Assets/Break.png';
import MessagePage from "../../Common/Tailwind/Page/MessagePage";
import SelectionDropdown from '../../Common/SelectionDropdown';
import SelectionDropdownMultiple from '../../Common/SelectionDropdownMultiple';
import TestRemainingTime from '../../Test/TestRemainingTime';
import { SLOT_TEST_TYPES, TEST_CREATE_TYPE_BASIC, securedFetchTests } from '../../../services/TestService';
import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import { UnauthorizedError } from '../../../services/Errors';

import TestHeader from '../../Test/TestHeader';
import NTATestView from '../../Test/NTA/NTATestView';
import StudentTestView from '../../Test/StudentTest/StudentTestView';

const SlotTestView = ({ lectureData, user = STUDENT_USER_TYPE, testType }) => {
    const [tests, setTests] = useState([]);
    const [selectedTest, setSelectedTest] = useState({});
    const [testStatus, setTestStatus] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const makeFilter = () => {
        const filter = {
            timetable_slot_ids: [lectureData.id],
            test_types: [testType]
        };
        return filter;
    };

    const fetchAllTests = async () => {
        const allTestsResponse = await securedFetchTests(TEST_CREATE_TYPE_BASIC, makeFilter(), navigateCallbackOptions(navigate));
        if (allTestsResponse === null) {
            return null;
        }
        return allTestsResponse.data;
    };

    const fetchAndSetAllTests = async () => {
        const response = await fetchAllTests();
        if (response === null) {
            return;
        }
        setTests(response);
    };

    const onTestEnd = () => {
        setSelectedTest(null);
    }

    const onRefreshTest = () => {
        setRefreshKey(prevKey => prevKey + 1);
    }

    useEffect(() => {
        (async () => {
            fetchAndSetAllTests();
            setSelectedTest({});
        })();
    }, [testType]);

    const onTestSelectionChange = (selection) => {
        const testId = selection ? selection.value : null;
        if (testId) {
            setSelectedTest({ id: selection.value, test_name: selection.label });
        }
    };

    const renderTest = () => {
        if (!selectedTest || !selectedTest.id) {
            return <></>;
        }
        return (
            <>
                {/* Test Status  */}
                <TestHeader testData={tests.find(test => test.id === selectedTest.id)} testStatus={testStatus} setTestStatus={setTestStatus} user={user} refreshKey={refreshKey} onRefreshClick={onRefreshTest} />
                {testStatus !== null && testStatus !== 'NOT STARTED' && (
                    <StudentTestView id={selectedTest.id} user={user} onTestEnd={onTestEnd} testStatus={testStatus} refreshKey={refreshKey} />
                )}
            </>
        );
    }

    const renderSelectLiveQuiz = () => {
        if (!tests.length) {
            return <MessagePage imgsrc={Break} title={t('noLiveQuiz')} message1={""} message2={""} message3={""} />
        }

        return (
            <div class="flex flex-col py-4 mt-4 overflow-x-auto overflow-y-auto md:justify-center dark:border-gray-700">

                <h3 class="text-xl font-semibold text-center text-[var(--SecondaryColor)] capitalize lg:text-2xl dark:text-white">
                    {t('liveQuiz')}
                </h3>

                <div class="flex justify-center items-center">
                    {tests.length > 0 && tests.map((test) => (
                        <button
                            key={test.id}
                            className={`h-12 px-8 py-2 -mb-px text-sm text-center bg-transparent border-b-2 sm:text-base whitespace-nowrap focus:outline-none
                                ${selectedTest.id === test.id ? 'text-blue-600 border-blue-500 dark:border-blue-400 dark:text-blue-300'
                                    : 'text-gray-700 border-gray-200 dark:text-white cursor-base dark:border-gray-700 dark:hover:border-gray-400 hover:border-gray-400'}`}
                            onClick={() => { setSelectedTest({ id: test.id, test_name: test.test_name }) }}>
                            {test.test_name}
                        </button>
                    ))}
                </div>

            </div >
        );
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col justify-center items-center gap-2 sm:gap-3 md:gap-6 p-2 sm:p-4 md:p-6 lg:p-8">

                {/* Select Live Quiz */}
                {renderSelectLiveQuiz()}
                <div className="testFilter flex">
                    <SelectionDropdown
                        className="slotTestSelectionDropdown"
                        name=""
                        itemList={tests}
                        selectedItem={selectedTest}
                        onSelectionChange={onTestSelectionChange}
                        nameField='test_name'
                        valueField='id'
                        placeHolderText={"Select Live Quiz"} />
                </div>

                {/* Show Live Quiz*/}
                <div className="quizDiv flex" id={testType}>
                    {selectedTest.id && renderTest()}
                </div>
            </div>
        </div>
    );
}

export default SlotTestView;
