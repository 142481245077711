import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { DialogTitle } from '@headlessui/react'

import BaseRegisterForm from '../Authentication/BaseRegisterForm';
import SelectionDropdown from '../Common/SelectionDropdown';
import { securedFetchSubjects } from '../../services/SyllabusService';
import { BsCloudUpload } from 'react-icons/bs'
import { ADMIN_USER_TYPE, securedRegisterUser } from '../../services/UserService';
import { navigateCallbackOptions } from '../../services/AuthenticationService';
import SelectionDropdownMultiple from '../Common/SelectionDropdownMultiple';
import { CloudArrowUpIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import Dropdown from '../Common/Tailwind/SelectMenus/Dropdown';

const TeacherRegisterForm = ({ onTeacherCreate = null, setIsSubmitted, user = ADMIN_USER_TYPE }) => {

    const [email, setEmail] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [fullName, setFullName] = useState("");
    const [subjects, setSubjects] = useState([]);
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const fetchAndSetSubjects = async () => {
        const subjectsResponse = await securedFetchSubjects(navigateCallbackOptions(navigate));
        if (subjectsResponse === null) {
            return null;
        }
        return subjectsResponse.data;
    };

    useEffect(() => {
        (async () => {
            const subjectsResponse = await fetchAndSetSubjects();
            if (subjectsResponse === null) {
                return;
            }
            setSubjects(subjectsResponse);
        })();
    }, []);

    const onSelectedSubjectChange = async (selection) => {
        const selectedIds = selection.map(sel => sel.value);
        const selectedSubjectsFromSelect = subjects.filter(subject => selectedIds.includes(subject.id));
        setSelectedSubjects(selectedSubjectsFromSelect);
    };

    const resetState = () => {
        setEmail("");
        setContactNumber("");
        setFullName("");
    }

    const handleRegister = async () => {
        setIsSubmitted(true);
        const data = {
            user_data: {
                first_name: fullName,
                last_name: "",
                email: email,
                phone_number: contactNumber,
                password: 'password'
            },
            subjects: selectedSubjects.map(selectedSubject => ({ subject_id: parseInt(selectedSubject.id) }))
        }
        const response = await securedRegisterUser('teacher', data, navigateCallbackOptions(navigate));
        if (response === null) {
            setIsSubmitted(false);
            return;
        }
        resetState();
        setIsSubmitted(false);
        if (onTeacherCreate) {
            await onTeacherCreate();
        }
    };

    return (

        <div>
            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                <UserPlusIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
            </div>

            <div className="mt-3 text-center sm:mt-5">
                <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                    {t('registerNewTeacher')}
                </DialogTitle>

                <div className="mt-10 mb-4 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-4"
                        onSubmit={(event) => (event.preventDefault())}>

                        <BaseRegisterForm
                            email={email}
                            setEmail={setEmail}
                            contactNumber={contactNumber}
                            setContactNumber={setContactNumber}
                            fullName={fullName}
                            setFullName={setFullName} />

                        {/* Select Subjects */}
                        <Dropdown
                            list={subjects}
                            selected={selectedSubjects}
                            onSelectionChange={onSelectedSubjectChange}
                            label={t('subjects')}
                            nameField='subject_name'
                            valueField='id'
                            required={true}
                            multiple={true}
                            // isAddable={true}
                            className="flex items-center justify-center gap-2 max-w-full w-full"
                        />

                        <button className="flex w-full justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[var(--PrimaryColor)]"
                            onClick={handleRegister}>
                            {t('submitCapitalized')}
                            <CloudArrowUpIcon className="w-5 h-5" />
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
};

export default TeacherRegisterForm;
