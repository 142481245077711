import React from 'react'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { securedFetchBatchesByFilter, securedFetchBatchesByGrade, securedFetchGrades } from '../../services/BatchService';

import { UnauthorizedError } from '../../services/Errors';
import { ADMIN_USER_TYPE, TEACHER_USER_TYPE, STUDENT_USER_TYPE } from '../../services/UserService';
import { authErrorCallbackNavigate, navigateCallbackOptions } from '../../services/AuthenticationService';
import { updateSearchParams } from '../../services/CommonService';
import Dropdown from '../Common/Tailwind/SelectMenus/Dropdown';
import { PlusCircleIcon } from '@heroicons/react/24/outline';

const TypeSeriesFilter = ({ showTitle = false, types, selectedType, series, selectedSeries,
    setType, setSelectedType, setSeries, setSelectedSeries,
    onSelectedTypeChange, onSelectedSeriesChange, user = ADMIN_USER_TYPE,
    setTestCreateOpen }) => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <div className="w-full flex flex-col justify-center items-center gap-2 md:gap-4 z-2">

            {showTitle &&
                <h3 className="mt-2 text-lg font-semibold leading-6 text-[var(--SecondaryColor)]">
                    {t('selectTestType')}
                </h3>
            }

            <div className="mt-2 flex flex-col sm:flex-row items-center justify-center gap-4 md:gap-6 lg:gap-8 w-full">

                {/* Select Type */}
                <Dropdown
                    list={types}
                    selected={selectedType}
                    onSelectionChange={onSelectedTypeChange}
                    label={t('types')}
                    nameField='name'
                    valueField='id'
                    required={true}
                    multiple={true}
                    // isAddable={true}
                    className='flex items-center justify-center gap-2 min-w-48'
                />

                {/* Select Series */}
                <Dropdown
                    list={series}
                    selected={selectedSeries}
                    onSelectionChange={onSelectedSeriesChange}
                    label={t('series')}
                    nameField='test_series_name'
                    valueField='id'
                    required={true}
                    multiple={true}
                    isSearchable={true}
                    className='flex items-center justify-center gap-2 min-w-48 max-w-96'
                />

                {/* Create Batch */}
                <button onClick={() => setTestCreateOpen(true)}
                    data-tooltip-id="tooltip-create-new-test"
                    data-tooltip-content={t('createNewTest')}
                    data-tooltip-place="right">
                    <PlusCircleIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                </button>
            </div>

        </div>
    )
};

export default TypeSeriesFilter;