import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import AdminLogo from "../../Assets/AdminProfile.png";
import TeacherLogo from "../../Assets/Teacher.png";
import StudentLogo from "../../Assets/Student.png";
import AdminSidebarItems from "./Sidebar/AdminSidebarItems";
import TeacherSidebarItems from "./Sidebar/TeacherSidebarItems";
import StudentSidebarItems from "./Sidebar/StudentSidebarItems";
import Spinner from "../Common/Tailwind/Spinner";
import PageHeader from "../Common/Tailwind/Page/PageHeader";
import PageSidebar from "../Common/Tailwind/Page/PageSidebar";
import DashboardMain from "./DashboardMain";

import { securedFetchUserById, ADMIN_USER_TYPE, TEACHER_USER_TYPE, STUDENT_USER_TYPE } from "../../services/UserService";
import { navigateCallbackOptions } from "../../services/AuthenticationService";
import { securedFetchNextSlot, securedFetchTimetableSlotById } from "../../services/TimetableService";
import { securedFetchSlots } from "../../services/BatchService";


const Dashboard = ({ user = ADMIN_USER_TYPE }) => {

    const [userInfo, setUserInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [selectedTab, setSelectedTab] = useState('lectures');
    const navigate = useNavigate();

    const { t } = useTranslation();

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const fetchedUserInfo = await securedFetchUserById(navigateCallbackOptions(navigate));
            if (fetchedUserInfo === null) {
                return;
            }
            setUserInfo(fetchedUserInfo);
            setIsLoading(false);
        })();
    }, []);

    const renderMain = () => {
        if (isLoading) {
            return <Spinner />;
        }
        return (
            <main className="w-full h-full md:py-10">
                {userInfo && (
                    <DashboardMain selectedTab={selectedTab} user={user} />
                )}
            </main>
        )
    }

    return (
        <div className="relative flex flex-col flex-1 w-full h-full items-center gap-2 sm:gap-3 md:gap-4 text-[var(--textColor)]">

            {/* Page Header */}
            {/* {user === ADMIN_USER_TYPE && (
                <PageHeader pageHeaderText={t("boostProductivity")} />
            )}

            {user === TEACHER_USER_TYPE && (
                <PageHeader pageHeaderText={t("engageInteractAndSupportYourStudentsWithJEETA")} />
            )}

            {user === STUDENT_USER_TYPE && (
                <PageHeader pageHeaderText={t("makeYourDreamComeTrueWithJEETA")} />
            )} */}

            {/* Page Main */}
            <div className="flex flex-col md:flex-row w-full bg-white overflow-hidden">

                {/* Sidebar*/}
                {user === ADMIN_USER_TYPE && (
                    <PageSidebar sidebarLogo={AdminLogo} sidebarTitle="Dashboard" sidebarItems={AdminSidebarItems} user={user} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                )}

                {user === TEACHER_USER_TYPE && (
                    <PageSidebar sidebarLogo={TeacherLogo} sidebarTitle="Dashboard" sidebarItems={TeacherSidebarItems} user={user} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                )}

                {user === STUDENT_USER_TYPE && (
                    <PageSidebar sidebarLogo={StudentLogo} sidebarTitle="Dashboard" sidebarItems={StudentSidebarItems} user={user} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                )}

                {/* Main */}
                {renderMain()}
            </div>

        </div>
    )
}

export default Dashboard