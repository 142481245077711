import React from 'react'
import { useState, useEffect } from "react";

import Navbar from '../../Components/Navbar/Navbar'
import Hero from '../../Components/Hero/Hero';
import Footer from '../../Components/Footer/Footer';
import bkgImage from '../../Assets/Test.png'
import { STUDENT_USER_TYPE } from '../../services/UserService';
import { StudentNavItems } from './StudentNavItems';
import Syllabus from '../../Components/Syllabus/Syllabus';

const StudentSyllabus = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <Navbar navItems={StudentNavItems} user={STUDENT_USER_TYPE} />

            <div className="flex flex-1 justify-center items-center">
                <Syllabus user={STUDENT_USER_TYPE} />
            </div>

            <Footer user={STUDENT_USER_TYPE} />

        </div >
    )
}

export default StudentSyllabus;