import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import './NTA.css'

import { ADMIN_USER_TYPE, STUDENT_USER_TYPE, securedFetchUsers } from '../../../services/UserService';
import { AiOutlineRight } from 'react-icons/ai';

Modal.setAppElement('#root');

const NTASubmitWarning = ({ isOpen, onRequestClose, handleSubmitTest, user = STUDENT_USER_TYPE, parentId }) => {

    const { t } = useTranslation();
    const handleSubmit = async () => {
        await handleSubmitTest();
        onRequestClose();
    }
    return (
        <Modal overlayClassName="customModal" className="testModal flex" isOpen={isOpen} onRequestClose={onRequestClose} shouldCloseOnOverlayClick={false} parentSelector={() => parentId ? document.getElementById(parentId) : document.body}>
            <span>{t('onceYouSubmitYouWontBeAbleToAttemptTheTestAgain')} <br />
                {t('areYouSureYouWantToSubmit')}
            </span>

            <div className="btns flex">
                <button className="btn" onClick={handleSubmit}>{t('yesCapital')}</button>
                <button className="btn" onClick={onRequestClose}>{t('noCapital')}</button>
            </div>
        </Modal >
    );
}

export default NTASubmitWarning;
