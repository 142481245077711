import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'

import './profile.css'

import { ADMIN_USER_TYPE, securedUpdateUserPassword } from "../../services/UserService";
import { navigateCallbackOptions, securedLogoutUser } from "../../services/AuthenticationService";
import { RiLockPasswordLine } from 'react-icons/ri';
import { ArrowUpTrayIcon, XMarkIcon } from '@heroicons/react/24/outline'

const ChangePasswordForm = ({ isOpen = false, onRequestClose = null, user = ADMIN_USER_TYPE }) => {

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");

    const navigate = useNavigate();

    const onTextChange = (setter) => (event) => {
        setter(event.target.value);
    }

    const handleSubmit = async () => {
        const data = {
            old_password: oldPassword,
            password: newPassword
        }
        const updateResponse = await securedUpdateUserPassword(data, navigateCallbackOptions(navigate));
        if (updateResponse === null) {
            return;
        }
        const logoutResponse = await securedLogoutUser(navigateCallbackOptions(navigate));
        if (logoutResponse === null) {
            return;
        }
        onRequestClose();
        navigate('/');
    };



    return (
        <Dialog open={isOpen} onClose={onRequestClose} className="relative z-10">

            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                            <button
                                type="button"
                                onClick={onRequestClose}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-[var(--PrimaryColor)] focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                            </button>
                        </div>

                        <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                <RiLockPasswordLine aria-hidden="true" className="h-6 w-6 text-green-600" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                    Change Your Password
                                </DialogTitle>

                                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                                    <formn className="space-y-4">
                                        <div className="flex justify-start items-center">
                                            <label htmlFor="oldPassword" className="block text-sm font-medium leading-6 text-gray-900">
                                                Old Password
                                            </label>
                                            <div className="ml-2">
                                                <input
                                                    id="oldPassword"
                                                    name="oldPassword"
                                                    type="oldPassword"
                                                    value={oldPassword}
                                                    placeholder='Old Password'
                                                    onChange={onTextChange(setOldPassword)}
                                                    required
                                                    autoComplete="password"
                                                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[var(--PrimaryColor)] sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                        </div>

                                        <div className="flex justify-start items-center">
                                            <label htmlFor="newPassword" className="block w-200 text-sm font-medium leading-6 text-gray-900">
                                                New Password
                                            </label>
                                            <div className="ml-2">
                                                <input
                                                    id="newPassword"
                                                    name="newPassword"
                                                    type="newPassword"
                                                    value={newPassword}
                                                    placeholder='New Password'
                                                    onChange={onTextChange(setNewPassword)}
                                                    required
                                                    autoComplete="password"
                                                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[var(--PrimaryColor)] sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                        </div>

                                        <div className="flex justify-start items-center">
                                            <label htmlFor="confirmNewPassword" className="block text-sm font-medium leading-6 text-gray-900">
                                                Confirm
                                            </label>
                                            <div className="ml-2">
                                                <input
                                                    id="confirmNewPassword"
                                                    name="confirmNewPassword"
                                                    type="confirmNewPassword"
                                                    value={confirmNewPassword}
                                                    placeholder='Confirm New Password'
                                                    onChange={onTextChange(setConfirmNewPassword)}
                                                    required
                                                    autoComplete="password"
                                                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[var(--PrimaryColor)] sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <button
                                                onClick={handleSubmit}
                                                disabled={newPassword !== confirmNewPassword}
                                                className="flex w-full justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[var(--PrimaryColor)]"
                                            >
                                                <span>Submit</span>
                                                <ArrowUpTrayIcon className="icon" />
                                            </button>
                                        </div>

                                    </formn>

                                </div>

                            </div>
                        </div>

                    </DialogPanel>
                </div>
            </div>
        </Dialog>

        // <>
        //     <Modal overlayClassName="customModal" className="profileModal" isOpen={isOpen} onRequestClose={onRequestClose}>
        //         <div className="register-container flex">
        //             <div className="registerTitle flex">
        //                 <h3 className="title">
        //                     Change your password
        //                 </h3>
        //             </div>

        //             <div className="inputBox flex">

        //                 <div className="contactInput flex">
        //                     <div className="input flex">
        //                         <label className="inputLabel" htmlFor="oldPassword">Old Password</label>
        //                         <input type="oldPassword" name="oldPassword" value={oldPassword} onChange={onTextChange(setOldPassword)} placeholder='Old Password' required />
        //                         <RiLockPasswordLine className="icon" />
        //                     </div>
        //                 </div>


        //                 <div className="contactInput flex">
        //                     {/* <label className="title" htmlFor="EMAIL">Email</label> */}
        //                     <div className="input flex">
        //                         <label className="inputLabel" htmlFor="newPassword">New Password</label>
        //                         <input type="newPassword" name="newPassword" value={newPassword} onChange={onTextChange(setNewPassword)} placeholder='New Password' required />
        //                         <RiLockPasswordLine className="icon" />
        //                     </div>
        //                 </div>

        //                 <div className="contactInput flex">
        //                     {/* <label className="title" htmlFor="CONTACT NUMBER">Contact Number</label> */}
        //                     <div className="input flex">
        //                         <label className="inputLabel" htmlFor="confirmNewPassword">Confirm</label>
        //                         <input type="confirmNewPassword" name="confirmNewPassword" value={confirmNewPassword} onChange={onTextChange(setConfirmNewPassword)} placeholder='Confirm New Password' required />
        //                         <RiLockPasswordLine className="icon" />
        //                     </div>
        //                 </div>
        //             </div>

        //             <button className='btn flex' id="submit" onClick={handleSubmit} disabled={newPassword !== confirmNewPassword}>
        //                 SUBMIT <BsCloudUpload className="icon" />
        //             </button>

        //             <AiFillCloseCircle className="icon" id="close" onClick={onRequestClose} />

        //         </div>

        //     </Modal>

        // </>
    )
};

export default ChangePasswordForm;
