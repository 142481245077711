import React from 'react'

import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer';

import { AdminNavItems } from './AdminNavItems';
import { ADMIN_USER_TYPE } from '../../services/UserService';
import { useParams } from 'react-router-dom';
import SlotView from '../../Components/Slots/SlotView';

const AdminSlots = () => {
    const { slotId } = useParams();
    return (
        <div className="flex flex-col min-h-screen">
            <Navbar navItems={AdminNavItems} />
            <SlotView slotId={parseInt(slotId)} user={ADMIN_USER_TYPE} />
            <Footer />
        </div>
    )
}

export default AdminSlots;
