import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Logo from '../../Assets/LiveClass.png'
import StudentList from "./StudentList";
import TeacherList from "./TeacherList";
import { ADMIN_USER_TYPE, MEMBER_TYPES } from "../../services/UserService";
import PageSidebar from "../Common/Tailwind/Page/PageSidebar";
import SidebarItems from "./SidebarItems";

const MemberPage = ({ user = ADMIN_USER_TYPE }) => {

    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState('student');

    const onTypeChange = (selection) => {
        setSelectedTab(selection);
    };

    const renderMain = () => {

        return (
            <div class="flex flex-col w-full">
                {selectedTab === "student" ? (
                    <StudentList />
                ) : (
                    <TeacherList />
                )}
            </div>
        );
    };

    return (
        <div className="relative flex flex-col flex-1 w-full h-full items-center gap-2 sm:gap-3 md:gap-4 text-[var(--textColor)]">

            {/* Page Header */}
            {/* <PageHeader pageHeaderText={t('upgradeMemberManagement')} /> */}

            {/* Page Main */}
            <div className="flex flex-col md:flex-row w-full bg-white overflow-hidden">

                {/* Sidebar*/}
                {user === ADMIN_USER_TYPE && (
                    <PageSidebar sidebarLogo={Logo} sidebarTitle="Members" sidebarItems={SidebarItems} user={user} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                )}

                {/* Main */}
                {renderMain()}
            </div>

        </div>

    )
};

export default MemberPage;
