export const TeacherNavItems = [
    {
        title: "dashboard",
        url: "/teacher/dashboard",
        cName: "navLink",
    },
    {
        title: "syllabus",
        url: "/teacher/syllabus",
        cName: "navLink",
    },
    {
        title: "liveclass",
        url: "/teacher/liveclass",
        cName: "navLink",
    },
    // {
    //     title: "Questions",
    //     url: "/teacher/questions",
    //     cName: "navLink",
    // },
    // {
    //     title: "Analytics",
    //     url: "/teacher/analytics",
    //     cName: "navLink"
    // },
    {
        title: "profile",
        url: "/teacher/profile",
        cName: "navLink",
    }
]