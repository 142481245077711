import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ADMIN_USER_TYPE, TEACHER_USER_TYPE, STUDENT_USER_TYPE } from '../../services/UserService'
import { navigateCallbackOptions } from '../../services/AuthenticationService'
import { securedSubmitFeedback } from '../../services/FeedbackService'

const Footer = ({ user = ADMIN_USER_TYPE }) => {

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const navigate = useNavigate();

  const handleFeedbackSubmitClick = async () => {
    const submitResponse = await securedSubmitFeedback({ message: feedbackMessage }, navigateCallbackOptions(navigate));
    if (submitResponse === null) {
      return;
    }
    alert("Thank you for your valuable feedback!");
    setFeedbackMessage("");
  }

  return (
    <footer className="sticky bottom-0 w-full p-1 bg-[var(--bodyColor)]">

      <div class="relative w-full flex flex-col md:flex-row justify-between items-center">
        <span className="text-[var(--SecondaryColor)] text-xs">Empowering Learning: Stay Motivated with AI Guidance</span>
        <span className="text-[var(--SecondaryColor)] text-xs">COPYRIGHTS RESERVED - JEETA 2024</span>
      </div >

    </footer>
  )
}

export default Footer

