import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import Modal from 'react-modal';

import './testSubject.css'

import { useTest } from '../TestContext';
import SelectionDropdown from '../../Common/SelectionDropdown';
import { UnauthorizedError } from '../../../services/Errors';
import { securedCreateTestSections, securedCreateTestSubjects } from '../../../services/TestService';
import { securedFetchSubjects } from '../../../services/SyllabusService';
import { ADMIN_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';

import { AiFillCloseCircle } from "react-icons/ai";
import { BiMessageSquareAdd } from 'react-icons/bi';
import { render } from '@fullcalendar/core/preact.js';
import Spinner from '../../Common/Tailwind/Spinner';

Modal.setAppElement('#root');  // This is important for accessibility reasons

const TestSubjectCreate = ({ isOpen, onRequestClose, testId, getOrder, onAddSubject = null, user = ADMIN_USER_TYPE }) => {
    const [subjects, setSubjects] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const navigate = useNavigate();

    const {
        testSubjects,
        setTestSubjects,
        testSections,
        setTestSections,
        testQuestions,
        setTestQuestions,
        testParagraphs,
        setTestParagraphs,
    } = useTest();

    useEffect(() => {
        (async () => {
            const subjectsResponse = await securedFetchSubjects(navigateCallbackOptions(navigate));
            if (subjectsResponse === null) {
                return;
            }
            const alreadyCreatedSubjectIds = testSubjects.map((testSubject) => testSubject.subject_id);
            const subjectsToShow = subjectsResponse.data.filter(subject => !alreadyCreatedSubjectIds.includes(subject.id));
            setSubjects(subjectsToShow);
            setSelectedSubject(subjectsToShow[0]);
        })();
    }, [testSubjects]);

    const handleSubmit = async () => {
        setIsSaving(true);
        const data = {
            subject_id: selectedSubject.id,
            subject_order: getOrder()
        }
        const response = await securedCreateTestSubjects(testId, [data], navigateCallbackOptions(navigate));
        if (response === null) {
            setIsSaving(false);
            return;
        }
        if (onAddSubject !== null) {
            await onAddSubject(response[0].test_subject_id);
        }
        setIsSaving(false);
        onRequestClose();
    }

    const onSelectedSubjectChange = (selection) => {
        const subjectId = selection ? selection.value : null;
        if (subjectId) {
            setSelectedSubject({ id: subjectId, subject_name: selection.label });
        }
    }

    const renderTestSubjectCreate = () => {
        if (isSaving) {
            return <Spinner />;
        }
        return (
            <>
                <h3 className="modalTitle">Add Test Subject</h3>
                <SelectionDropdown className="subjectCreateSelectionDropdown" name="Subject" itemList={subjects} selectedItem={selectedSubject} valueField={'id'} nameField={'subject_name'} onSelectionChange={onSelectedSubjectChange} required={true} />
                <button className="btn flex" onClick={handleSubmit}>
                    Add
                    <BiMessageSquareAdd className="icon" />
                </button>
                <AiFillCloseCircle className="icon" id='close' onClick={onRequestClose} />
            </>
        )
    }

    return (
        <Modal overlayClassName="customModal" className="testSubjectModal flex" isOpen={isOpen} onRequestClose={onRequestClose}>

            {renderTestSubjectCreate()}
        </Modal>
    );
}

export default TestSubjectCreate;
