import React, { useEffect, useState } from "react";

const Hero = (props) => {

  const [img, setImg] = useState(0);

  // Update the key when the image source changes
  useEffect(() => {
    setImg(prevImg => prevImg + 1);
  }, [props.img]); // Dependency on image source

  return (
    <div className='flex flex-col items-center justify-center w-full bg-white mt-10
    transition data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-300 data-[enter]:ease-out data-[leave]:ease-in'>

      <img
        key={img}
        className={`${props.cName} object-contain`}
        src={props.img}
        alt="hero-img" />

      <div className="flex flex-col items-center text-center w-full">

        {props.title.length > 0 &&
          <h1 className="w-full bg-[var(--PrimaryBkg)] text-3xl md:text-4xl font-extrabold text-white py-2">
            {props.title}
          </h1>}

        {props.description.length > 0 &&
          <h2 className="w-full bg-[var(--SecondaryBkg)] text-lg md:text-xl font-medium text-white py-1">
            {props.description}
          </h2>}
      </div>

    </div>
  )
}

export default Hero