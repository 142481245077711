import React, { useState, useEffect } from 'react';

import './Syllabus.css'

import { ADMIN_USER_TYPE } from '../../services/UserService';
import SlotCardList from '../Slots/SlotCard/SlotCardList';


const SyllabusSlots = ({ syllabusFilter, user = ADMIN_USER_TYPE }) => {

    const [slotSearchFilter, setSlotSearchFilter] = useState(null);
    const [page, setPage] = useState(1);

    const makeSearchFilter = () => {
        let selectedTopics = [];
        if (!syllabusFilter.topics || !syllabusFilter.mainTopics) {
            return {};
        }
        if (syllabusFilter.selectedTopics.length > 0) {
            selectedTopics = selectedTopics.concat(syllabusFilter.selectedTopics);
        } else if (syllabusFilter.selectedMainTopics.length > 0) {
            selectedTopics = selectedTopics.concat(syllabusFilter.selectedMainTopics);
        }
        return {
            subjects: syllabusFilter.subjects,
            selectedSubjects: syllabusFilter.selectedSubjects,
            topics: syllabusFilter.topics.concat(syllabusFilter.mainTopics),
            selectedTopics: selectedTopics
        }
    }

    useEffect(() => {
        setSlotSearchFilter(makeSearchFilter());
    }, [syllabusFilter]);

    if (!slotSearchFilter) {
        return;
    }

    return (
        <div className="syllabusSlotList flex">
            <SlotCardList syllabusFilter={slotSearchFilter} showDates={true} user={user} page={page} setPage={setPage} />
        </div>
    );
};

export default SyllabusSlots;