import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import videojs from 'video.js';
import 'videojs-mobile-ui';
import 'video.js/dist/video-js.css';
import "videojs-markers";
import 'videojs-mobile-ui/dist/videojs-mobile-ui.css';
import 'videojs-hls-quality-selector';
import { ZoomMtg } from '@zoom/meetingsdk';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import Drawer from '../Common/Tailwind/Drawers/Drawer';

import './zoom.css'

import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../services/UserService';
import { securedFetchZoomMeetingById, securedFetchZoomMeetingHostCredentialsById, securedfetchZoomMeetingRegistrantCredentialsById, securedFetchZoomMeetingStatusById } from '../../services/ZoomMeetingService';
import { navigateCallbackOptions } from '../../services/AuthenticationService';
import { useInterval } from '../Hooks/useInteval';
import { securedFetchSlotStatus, securedFetchTimetableSlotById, securedFetchTimetableSlotStatusById } from '../../services/TimetableService';
import { zoom } from 'd3';
import Spinner from '../Common/Tailwind/Spinner';
import { useSearchParams } from 'react-router-dom';



const ZoomView = ({ slotId, user = ADMIN_USER_TYPE }) => {

    const navigate = useNavigate();
    const [meetingData, setMeetingData] = useState(null);
    const [lectureData, setLectureData] = useState(null);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [toggleDrawer, setToggleDrawer] = useState(true);
    const [zoomUserData, setZoomUserData] = useState(null);
    const endPage = `${window.location.origin}/zoom/${user}/liveclass/slots/${slotId}/meeting?externalStatus=finished`;

    const [studentButtonsToHide, setStudentButtonsToHide] = useState(['ae', 't', 'l', 'pf', 'pff']);
    const [teacherButtonsToHide, setTeacherButtonsToHide] = useState([]);

    const [searchParams, setSearchParams] = useSearchParams();

    const onClick = () => {
        navigate(-1);
    }

    useInterval(() => {
        (async () => {
            if (searchParams.get('externalStatus') === 'finished') {
                return;
            }
            const slotStatus = await securedFetchTimetableSlotStatusById(slotId, navigateCallbackOptions(navigate));
            if (slotStatus === null) {
                return;
            }
            if (!lectureData || !lectureData.zoom_meeting_id) {
                return;
            }
            const zoomStatus = await securedFetchZoomMeetingStatusById(lectureData.zoom_meeting_id, navigateCallbackOptions(navigate));
            if (zoomStatus === null) {
                return;
            }
            if (slotStatus.time_remaining < -3600 && zoomStatus.status === 'waiting') {
                navigate(endPage);
            }
        })();
    }, 600000);

    const closeTab = () => {
        window.opener = null;
        window.open("", "_self");
        window.close();
    };

    const onOpenInApp = () => {
        if (meetingData == null) {
            return;
        }
        const url = user === ADMIN_USER_TYPE ? meetingData.start_url : meetingData.join_url;
        window.open(url, '_blank');
        closeTab();
    }

    useEffect(() => {
        (async () => {
            const response = await securedFetchTimetableSlotById(slotId, navigateCallbackOptions(navigate));
            if (response === null) {
                return;
            }
            const slotStatus = await securedFetchSlotStatus(slotId, navigateCallbackOptions(navigate));
            if (slotStatus === null) {
                return;
            }
            if (slotStatus.status === 404) {
                alert("This slot's status was not found!");
                navigate(-1);
                return;
            }
            if (slotStatus.status !== 'finished' && response.zoom_meeting_id != null) {
                let userDataResponse = null;
                if (user !== STUDENT_USER_TYPE) {
                    userDataResponse = await securedFetchZoomMeetingHostCredentialsById(response.zoom_meeting_id, navigateCallbackOptions(navigate));
                } else {
                    userDataResponse = await securedfetchZoomMeetingRegistrantCredentialsById(response.zoom_meeting_id, navigateCallbackOptions(navigate))
                }
                if (userDataResponse != null) {
                    setMeetingData(userDataResponse);
                }
            }
            setLectureData({ ...response, status: slotStatus.status });
            ZoomMtg.preLoadWasm();
            ZoomMtg.prepareWebSDK();
        })();
    }, [slotId, navigate]);



    useEffect(() => {
        if (searchParams.get('externalStatus') === 'finished') {
            return;
        }
        const observer = new MutationObserver(() => {
            hideFullScreenLink();
        });

        const hideFullScreenLink = () => {
            const buttonsToHide = user === STUDENT_USER_TYPE ? studentButtonsToHide : teacherButtonsToHide;
            const setButtonsToHide = user === STUDENT_USER_TYPE ? setStudentButtonsToHide : setTeacherButtonsToHide;
            let removed = [];
            if (buttonsToHide.includes('fs')) {
                const fullScreenLink = document.querySelector('a[aria-label="Fullscreen"]')
                if (fullScreenLink) {
                    fullScreenLink.style.display = 'none';
                    removed.push('fs');
                }
            }
            if (buttonsToHide.includes('ae')) {
                const allEyesLink = document.querySelector('span[aria-label="All eyes on me"]');
                if (allEyesLink) {
                    allEyesLink.style.display = 'none';
                    removed.push('ae');
                }
            }
            if (buttonsToHide.includes('t')) {
                const timerLink = document.querySelector('button[aria-label="timer"]');
                if (timerLink) {
                    timerLink.style.display = 'none';
                    removed.push('t');
                }
            }
            if (buttonsToHide.includes('l')) {
                const laserLink = document.querySelector('button[aria-label="Laser"]');
                if (laserLink) {
                    laserLink.style.display = 'none';
                    removed.push('l');
                }
            }
            if (buttonsToHide.includes('pf')) {
                const presenFramesLink = document.querySelector('button[aria-label="Present frames"]')
                if (presenFramesLink) {
                    presenFramesLink.style.display = 'none';
                    removed.push('pf');
                }
            }
            if (buttonsToHide.includes('pff')) {
                const presenFramesFooterLink = document.querySelector('div[class="presentation-panel-footer"]')
                if (presenFramesFooterLink) {
                    presenFramesFooterLink.style.display = 'none';
                    removed.push('pff');
                }
            }
            const buttonsLeft = buttonsToHide.filter(btn => !removed.includes(btn))
            setButtonsToHide(buttonsLeft);
            if (buttonsLeft.length === 0) {
                observer.disconnect();
            }
        };

        observer.observe(document.body, {
            childList: true,
            subtree: true,
        });

        return () => {
            if (user === STUDENT_USER_TYPE) {
                observer.disconnect();
            }
        };
    }, []);

    useEffect(() => {
        if (searchParams.get('externalStatus') === 'finished') {
            closeTab();
        }
    }, [searchParams])


    const startMeeting = (role = 1) => {

        const credentials = role === 1 ? { zak: meetingData.zak } : { tk: meetingData.tk };
        document.getElementById('zmmtg-root').style.display = 'block'


        ZoomMtg.init({
            leaveUrl: endPage,
            patchJsMedia: true,
            leaveOnPageUnload: true,
            success: (success) => {
                ZoomMtg.join({
                    signature: meetingData.signature,
                    sdkKey: meetingData.sdk_key,
                    meetingNumber: meetingData.meeting_id,
                    passWord: meetingData.password,
                    userName: meetingData.user_name,
                    userEmail: meetingData.user_email,// meetingData.user_email,
                    ...credentials,
                    success: (success) => {
                        const liveClassElement = document.getElementById('live-class');
                        const zmmtgRoot = document.getElementById('zmmtg-root');
                        const drawerElement = document.getElementById('live-doubts-drawer');
                        if (liveClassElement && zmmtgRoot) {
                            liveClassElement.appendChild(zmmtgRoot);
                            zmmtgRoot.style.display = 'block';
                        }
                        if (drawerElement && zmmtgRoot) {
                            zmmtgRoot.appendChild(drawerElement);
                        }
                    },
                    error: (error) => {
                        alert(`Error in starting meeting: ${error.message}`);
                    }
                })

            },
            error: (error) => {
                alert(`Error in initializing meeting: ${error.message}`);
            }
        })
    }


    if (searchParams.get('externalStatus') === 'finished') {
        return (
            <div class="flex justify-center align-center" >
                <h1 class="mb-4 text-4xl  font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">The meeting has finished</h1>
            </div>
        );
    }


    if (lectureData === null) {
        return <Spinner />;
    }

    if (meetingData == null) {
        return <Spinner />;
    }


    return (
        <div id="live-class">
            <div id='live-doubts-drawer'>
                {!toggleDrawer && (
                    <div className="drawer-toggle-button" onClick={() => setToggleDrawer(true)}>
                        <ChevronLeftIcon className="h-10 w-10 
                        text-[var(--PrimaryColor)]" />
                    </div>)}

                <Drawer isFullscreen={isFullscreen} toggleDrawer={toggleDrawer} setToggleDrawer={setToggleDrawer} onOpenInApp={onOpenInApp} lectureData={lectureData} user={user} />
            </div>
            {startMeeting(meetingData.role)}
        </div>
    );
};

export default ZoomView;
