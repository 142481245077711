import { CgMediaLive } from "react-icons/cg";
import { FaInfoCircle } from "react-icons/fa";
import { MdOndemandVideo, MdOutlineQuiz } from "react-icons/md";
import { FaRegQuestionCircle } from "react-icons/fa";

// Sidebar Items
const SlotSidebarItems = [
    {
        icon: FaInfoCircle,
        heading: 'Info'
    },
    {
        icon: CgMediaLive,
        heading: 'Live Quiz'
    },
    {
        icon: FaRegQuestionCircle,
        heading: 'Doubts'
    },
    {
        icon: MdOndemandVideo,
        heading: 'Video'
    },
    {
        icon: MdOutlineQuiz,
        heading: 'Questions'
    }
];

export default SlotSidebarItems;