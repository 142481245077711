import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';

import './teacherTest.css'

import { TEACHER_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import { TEST_CREATE_TYPE_BASIC, canShowSectionMarks, fetchRemainingTime, securedAddTestQuestions, securedCreateTestSectionForTestId, securedDeleteTestQuestion, securedDeleteTestSection, securedDeleteTestSubject, securedFetchRemainingTime, securedFetchTestById, securedFetchTestSectionById, securedFetchTestSectionsByTestId, securedFetchTestSubjectById, securedMarkStudentTestAttempt, securedUpdateTestSectionOrder, securedUpdateTestSubjectOrder } from '../../../services/TestService';
import { securedFetchQuestionById, securedFetchQuestions, securedFetchQuestionsByIds } from '../../../services/QuestionService';
import { securedFetchSubjects } from '../../../services/SyllabusService';
import { UnauthorizedError } from '../../../services/Errors';
import TeacherTestQuestionView from './TeacherTestQuestionsView';
import TestSectionMarkingSchemeRender from '../TestSubject/TestSection/TestSectionMarkingSchemeRender';


const TeacherTestView = ({ id, onTestEnd = null, user = TEACHER_USER_TYPE, refreshKey = 0, testStatus = "NOT STARTED" }) => {

    const [testSubjects, setTestSubjects] = useState([]);
    const [testSections, setTestSections] = useState([]);
    const [testQuestions, setTestQuestions] = useState([]);
    const [testParagraphs, setTestParagraphs] = useState([]);
    const [testType, setTestType] = useState(null);
    const navigate = useNavigate();

    const fetchAndSetTest = async () => {
        const testResponse = await securedFetchTestById(TEST_CREATE_TYPE_BASIC, id, navigateCallbackOptions(navigate));
        const remainingTimeResponse = await securedFetchRemainingTime(TEST_CREATE_TYPE_BASIC, id, navigateCallbackOptions(navigate));
        if (remainingTimeResponse === null) {
            return;
        }
        if (remainingTimeResponse.time_remaining === null) {
            clearStates();
            return;
        }
        await setStatesFromResponse(testResponse);
    }
    useEffect(() => {
        (async () => {
            await fetchAndSetTest();
        })();
    }, [id, refreshKey]);


    useEffect(() => {
        (async () => {
            if (testStatus === 'STARTED') {
                const response = await securedMarkStudentTestAttempt(id, navigateCallbackOptions(navigate));
            }
        })();
    }, [testStatus]);

    const clearStates = () => {
        setTestSubjects([]);
        setTestSections([]);
        setTestQuestions([]);
        setTestParagraphs([]);
    }

    const setStatesFromResponse = async (testResponse) => {
        const testSubjectsFromResponse = [...testResponse.subjects];
        let testSectionsFromResponse = [];
        testSubjectsFromResponse.forEach((testSubject) => {
            testSectionsFromResponse = [...testSectionsFromResponse, ...testSubject.sections];
        })
        let testQuestionsFromResponse = [];
        let testParagraphsFromResponse = [];

        testSectionsFromResponse.forEach((testSection) => {
            testQuestionsFromResponse = [...testQuestionsFromResponse, ...testSection.test_questions];
            testParagraphsFromResponse = [...testParagraphsFromResponse, ...testSection.test_paragraphs];
        })
        await setTestQuestionsAndParagraphs(testQuestionsFromResponse, testParagraphsFromResponse);
        testResponse.subjects = null;
        testSubjectsFromResponse.forEach((testSubject) => { testSubject.sections = null });
        testSectionsFromResponse.forEach((testSection) => {
            testSection.test_questions = null;
            testSection.test_paragraphs = null;
        });

        setTestSubjects(testSubjectsFromResponse);
        setTestSections(testSectionsFromResponse);
        setTestType(testResponse.test_type);
    };

    const setTestQuestionsAndParagraphs = async (testQuestionsFromResponse, testParagraphsFromResponse) => {
        const questionIds = testQuestionsFromResponse.map((testQuestion) => (testQuestion.question_id));
        const paragraphIds = testParagraphsFromResponse.map((testParagraph) => (testParagraph.paragraph_id));
        const questionsResponse = await securedFetchQuestionsByIds('standalone', questionIds, navigateCallbackOptions(navigate));
        if (questionsResponse === null) {
            return;
        }
        const paragraphsResponse = await securedFetchQuestionsByIds('paragraph', paragraphIds, navigateCallbackOptions(navigate));
        if (paragraphsResponse === null) {
            return;
        }
        const questions = questionsResponse.data;
        const paragraphs = paragraphsResponse.data;
        testQuestionsFromResponse.forEach((testQuestion) => {
            const matchingQuestion = questions.find((question) => (question.id === testQuestion.question_id));
            testQuestion['question'] = matchingQuestion;
        });
        testParagraphsFromResponse.forEach((testParagraph) => {
            const matchingParagraph = paragraphs.find((paragraph) => (paragraph.id === testParagraph.paragraph_id));
            testParagraph['paragraph'] = matchingParagraph;
        });
        testQuestionsFromResponse = testQuestionsFromResponse.filter((testQuestion) => (testQuestion.question !== undefined));
        testParagraphsFromResponse = testParagraphsFromResponse.filter((testParagraph) => (testParagraph.paragraph !== undefined));
        setTestQuestions(testQuestionsFromResponse);
        setTestParagraphs(testParagraphsFromResponse);
    }

    const getTestQuestionsForSection = (testSectionId) => {
        let questionsForSection = testQuestions.filter(testQuestion => testQuestion.test_section_id === testSectionId);
        const paragraphsForSection = testParagraphs.filter(testParagraph => testParagraph.test_section_id === testSectionId);
        paragraphsForSection.forEach(testParagraph => {
            const paragraphQuestions = testParagraph.paragraph.questions.map(paragraphQuestion => ({ question_id: paragraphQuestion.question.id, test_section_id: testSectionId, question: paragraphQuestion.question }));
            paragraphQuestions.forEach(paragraphQuestion => {
                paragraphQuestion.question['basicInfo'] = testParagraph.paragraph.paragraph_text;
                paragraphQuestion.question['basicImages'] = testParagraph.paragraph.images;
            });
            const paragraphQuestionIds = paragraphQuestions.map(paragraphQuestion => paragraphQuestion.question_id);
            questionsForSection = questionsForSection.filter(testQuestion => !paragraphQuestionIds.includes(testQuestion.question_id));
            questionsForSection = [...questionsForSection, ...paragraphQuestions];
        })
        return questionsForSection;
    }

    const getTestParagraphsForSection = (testSectionId) => {
        return testParagraphs.filter(testParagraph => testParagraph.test_section_id === testSectionId);
    }


    return (
        <div className="studentTestView flex">

            {testSections.map(testSection => (

                <div key={testSection.id} className="studentTestSectionContainer flex" >

                    <div div className="studentTestSectionTop flex">

                        {testType !== "live" && testType !== "wrap up" && (
                            <h3>{testSection.section_description}</h3>
                        )}

                        {canShowSectionMarks(testType) && (
                            <TestSectionMarkingSchemeRender testSection={testSection} questionsForSection={getTestQuestionsForSection(testSection.id)} paragraphsForSection={getTestParagraphsForSection(testSection.id)} />)}
                    </div>

                    {(getTestQuestionsForSection(testSection.id).length > 0) && (
                        <TeacherTestQuestionView
                            questions={getTestQuestionsForSection(testSection.id)}
                            testStatus={testStatus}
                            testId={id}
                            showImages={true}
                            showAnswers={true}
                            user={user}
                            testType={testType}
                        />
                    )}
                </div>
            ))}
        </div >
    );
};

export default TeacherTestView;