import React from 'react'

import image from '../../Assets/LiveClass.png'
import Navbar from '../../Components/Navbar/Navbar';
import Hero from '../../Components/Hero/Hero';
import Footer from '../../Components/Footer/Footer';

import { StudentNavItems } from './StudentNavItems';
import { STUDENT_USER_TYPE } from '../../services/UserService';

import BatchView from '../../Components/Batches/BatchView';

const StudentLiveClass = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <Navbar navItems={StudentNavItems} user={STUDENT_USER_TYPE} />

            <div className="flex flex-1 justify-center items-center">
                <BatchView user={STUDENT_USER_TYPE} />
            </div>

            <Footer user={STUDENT_USER_TYPE} />
        </div>
    )
};

export default StudentLiveClass;