import React from 'react'

import image from '../../Assets/Student.png'

import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';

import { StudentNavItems } from './StudentNavItems';
import { STUDENT_USER_TYPE } from '../../services/UserService';

import UserProfile from '../../Components/Profile/UserProfile';

const StudentProfile = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <Navbar navItems={StudentNavItems} user={STUDENT_USER_TYPE} />

            <div className="flex flex-1 justify-center items-center">
                <UserProfile user={STUDENT_USER_TYPE} />
            </div>

            <Footer user={STUDENT_USER_TYPE} />

        </div>
    )
};

export default StudentProfile;