import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';

import './questionModal.css'

import { ADMIN_USER_TYPE } from '../../../services/UserService';
import QuestionTypeSingleChoice from './QuestionTypes/QuestionTypeSingleChoice';
import QuestionTypeMultiChoice from './QuestionTypes/QuestionTypeMultiChoice';
import QuestionTypeParagraph from './QuestionTypes/QuestionTypeParagraph';
import QuestionTypeMatchTheColumn from './QuestionTypes/QuestionTypeMatchTheColumn';
import { QUESTION_UPLOAD_TYPES } from '../../../services/QuestionService';
import QuestionTypeSubjective from './QuestionTypes/QuestionTypeSubjective';

Modal.setAppElement('#root'); // This is important for accessibility reasons

const QuestionEditTypeFactory = ({ inputQuestionData, isImageBased, selectedTopics, selectedTags, onRequestClose, onAddQuestion, isQuestionDataValid, setIsSaving, user = ADMIN_USER_TYPE }) => {

    const [selectedType, setSelectedType] = useState({});
    useEffect(() => {
        if (inputQuestionData.paragraph_text === undefined) {
            setSelectedType(QUESTION_UPLOAD_TYPES.find(type => type.id === inputQuestionData.question_subtype));
            return;
        }
        setSelectedType(QUESTION_UPLOAD_TYPES.find(type => type.id === 'paragraph'));

    }, [inputQuestionData])

    const renderQuestionCreate = () => {
        switch (selectedType.id) {
            case 'multi choice':
                return <QuestionTypeMultiChoice inputQuestionData={inputQuestionData} isImageBased={isImageBased} topics={selectedTopics} tags={selectedTags} hasSubmit={true} onRequestClose={onRequestClose} onAddQuestion={onAddQuestion} isQuestionDataValid={isQuestionDataValid} setIsSaving={setIsSaving} />;
            case 'paragraph':
                return <QuestionTypeParagraph inputParagraphData={inputQuestionData} isImageBased={isImageBased} topics={selectedTopics} tags={selectedTags} onRequestClose={onRequestClose} onAddQuestion={onAddQuestion} isQuestionDataValid={isQuestionDataValid} setIsSaving={setIsSaving} />;
            case 'match the column':
                return <QuestionTypeMatchTheColumn inputQuestionData={inputQuestionData} topics={selectedTopics} tags={selectedTags} onRequestClose={onRequestClose} onAddQuestion={onAddQuestion} isQuestionDataValid={isQuestionDataValid} />;
            case 'numerical':
                return <QuestionTypeSubjective type='numerical' inputQuestionData={inputQuestionData} isImageBased={isImageBased} topics={selectedTopics} tags={selectedTags} hasSubmit={true} onRequestClose={onRequestClose} onAddQuestion={onAddQuestion} isQuestionDataValid={isQuestionDataValid} setIsSaving={setIsSaving} />;
            case 'subjective':
                return <QuestionTypeSubjective inputQuestionData={inputQuestionData} isImageBased={isImageBased} topics={selectedTopics} tags={selectedTags} hasSubmit={true} onRequestClose={onRequestClose} onAddQuestion={onAddQuestion} isQuestionDataValid={isQuestionDataValid} setIsSaving={setIsSaving} />;
            default:
                return <QuestionTypeSingleChoice inputQuestionData={inputQuestionData} isImageBased={isImageBased} topics={selectedTopics} tags={selectedTags} hasSubmit={true} onRequestClose={onRequestClose} onAddQuestion={onAddQuestion} isQuestionDataValid={isQuestionDataValid} setIsSaving={setIsSaving} />;
        }
    }
    const renderQuestionType = () => {
        if (!selectedType || !selectedType.id) {
            return;
        }
        return (<>{renderQuestionCreate()}</>)
    }

    return (
        <>
            {renderQuestionType(selectedType)}
        </>

    );
}



export default QuestionEditTypeFactory;