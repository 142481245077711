import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Break from '../../Assets/Break.png';
import QuestionSlider from '../Questions/QuestionView/QuestionSlider/QuestionSlider';
import Spinner from '../Common/Tailwind/Spinner';
import MessagePage from '../Common/Tailwind/Page/MessagePage';

import { STUDENT_USER_TYPE } from '../../services/UserService';
import { areTestQuestionsVisible } from '../../services/TestService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { navigateCallbackOptions } from '../../services/AuthenticationService';
import { securedFetchQuestionsByIds } from '../../services/QuestionService';
import { securedFetchStudentRecommendationQuestions } from '../../services/RecommendationService';


const StudentQuestionRecommendation = ({ user = STUDENT_USER_TYPE }) => {
    const [questions, setQuestions] = useState(null);
    const [questionCountByStrategy, setQuestionCountByStrategy] = useState({});
    const [selectedTab, setSelectedTab] = useState('weak_topic');
    const [isTabletView, setIsTabletView] = useState(window.innerWidth < 860);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 600);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 860) {
                setIsTabletView(true);
            } else {
                setIsTabletView(false);
            }

            if (window.innerWidth < 600) {
                setIsMobileView(true);
            } else {
                setIsMobileView(false);
            }
        };

        // Set the sidebar state based on the initial window width
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    useEffect(() => {
        (async () => {
            const recommendedQuestions = await securedFetchStudentRecommendationQuestions('standalone', {}, navigateCallbackOptions(navigate));
            if (recommendedQuestions === null) {
                return;
            }
            const recommendedParagraphs = await securedFetchStudentRecommendationQuestions('paragraph', {}, navigateCallbackOptions(navigate));
            if (recommendedParagraphs === null) {
                return;
            }
            const allQuestionIds = recommendedQuestions.data.map(question => question.question_id);
            const allParagraphIds = recommendedParagraphs.data.map(paragraph => paragraph.paragraph_id);
            const allQuestionData = await securedFetchQuestionsByIds('standalone', allQuestionIds, null, navigateCallbackOptions(navigate));
            if (allQuestionData === null) {
                return;
            }
            const questionDatas = allQuestionData.data.filter(question => question.question_type === 'standalone').map(question => {
                return { ...recommendedQuestions.data.find(recommendedQuestion => recommendedQuestion.question_id === question.id), question: question, type: 'standalone' };
            })
            const allParagraphsData = await securedFetchQuestionsByIds('paragraph', allParagraphIds, null, navigateCallbackOptions(navigate));
            if (allParagraphsData === null) {
                return;
            }
            const paragraphDatas = allParagraphsData.data.map(paragraph => {
                return { ...recommendedParagraphs.data.find(recommendedParagraph => recommendedParagraph.paragraph_id === paragraph.id), question: paragraph, type: 'paragraph' };
            });
            const currentQuestionCountsByStrategy = {};
            recommendedQuestions.data.map(recommendedQuestion => {
                if (currentQuestionCountsByStrategy[recommendedQuestion.selection_strategy] == null) {
                    currentQuestionCountsByStrategy[recommendedQuestion.selection_strategy] = 0;
                }
                currentQuestionCountsByStrategy[recommendedQuestion.selection_strategy] += 1;
            });
            setQuestionCountByStrategy(currentQuestionCountsByStrategy);
            setQuestions([...questionDatas, ...paragraphDatas]);
        })();
    }, []);

    const renderQuestionRecommendation = () => {
        return (
            <div class="flex flex-col py-4 mt-4 overflow-x-auto overflow-y-auto md:justify-center dark:border-gray-700">

                <h3 class="text-xl font-semibold text-center text-[var(--SecondaryColor)] capitalize lg:text-2xl dark:text-white">
                    {t('topicRecommendation')}
                </h3>

                <div class="flex justify-center items-center">
                    <button className={`h-12 px-8 py-2 -mb-px text-sm text-center bg-transparent border-b-2 sm:text-base whitespace-nowrap focus:outline-none
                                ${selectedTab === "weak_topic" ? 'text-blue-600 border-blue-500 dark:border-blue-400 dark:text-blue-300'
                            : 'text-gray-700 border-gray-200 dark:text-white cursor-base dark:border-gray-700 dark:hover:border-gray-400 hover:border-gray-400'}`}
                        onClick={() => { setSelectedTab('weak_topic') }}>
                        {t('weak')}
                    </button>
                    <button className={`h-12 px-8 py-2 -mb-px text-sm text-center bg-transparent border-b-2 sm:text-base whitespace-nowrap focus:outline-none 
                                ${selectedTab === "less_solved_topic" ? 'text-blue-600 border-blue-500 dark:border-blue-400 dark:text-blue-300'
                            : 'text-gray-700 border-gray-200 dark:text-white cursor-base dark:border-gray-700 dark:hover:border-gray-400 hover:border-gray-400'}`}
                        onClick={() => { setSelectedTab('less_solved_topic') }}>
                        {t('lessSolved')}
                    </button>
                    <button className={`h-12 px-8 py-2 -mb-px text-sm text-center bg-transparent border-b-2 sm:text-base whitespace-nowrap focus:outline-none
                                ${selectedTab === "recently_learned_topic" ? 'text-blue-600 border-blue-500 dark:border-blue-400 dark:text-blue-300'
                            : 'text-gray-700 border-gray-200 dark:text-white cursor-base dark:border-gray-700 dark:hover:border-gray-400 hover:border-gray-400'}`}
                        onClick={() => { setSelectedTab('recently_learned_topic') }}>
                        {t('recentlyLearned')}
                    </button>
                </div>

            </div >
        );
    }

    const renderQuestionSlider = () => {
        if (questions === null) {
            return <Spinner />;
        }
        const filteredQuestionDatas = questions.filter(question => question.selection_strategy === selectedTab);
        const filteredQuestions = filteredQuestionDatas.map(question => question.question);
        const filteredQuestionTypes = filteredQuestionDatas.map(question => question.type);
        if (filteredQuestions.length === 0) {
            return (
                <MessagePage imgsrc={Break}
                    title={t('noRecommendedQuestions')}
                    message1={t('takeABreather')}
                    message2={t('fromJEETA')}
                    message3={t('reviewYourProgress')} />
            );
        }
        const filteredQuestionIds = filteredQuestions.map(question => question.id)
        const selectedQuestionId = filteredQuestions[0].id

        return (
            <div className="relative flex flex-col w-full h-full shadow-md" >
                <QuestionSlider questionTypes={filteredQuestionTypes} questionId={selectedQuestionId} showAnswers={true} showImages={true} type={'standalone'} user={user} questionIds={filteredQuestionIds} questionIdIndex={0} />
            </div>
        );
    }

    return (
        <div class="flex flex-col mt-4 overflow-x-auto overflow-y-auto md:justify-center dark:border-gray-700">
            {/* Select Recommendation Type */}
            {renderQuestionRecommendation()}

            {/* Recommended Questions */}
            {renderQuestionSlider()}
        </div>
    );
}

export default StudentQuestionRecommendation;