import React from 'react'

import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer';
import { AdminNavItems } from './AdminNavItems';
import { ADMIN_USER_TYPE } from '../../services/UserService';
import TestPage from '../../Components/Test/TestPage';

const AdminTests = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <Navbar navItems={AdminNavItems} user={ADMIN_USER_TYPE} />

            <div className="flex flex-1 justify-center items-center">
                <TestPage user={ADMIN_USER_TYPE} />
            </div>

            <Footer />
        </div>
    )
}

export default AdminTests;