import React from 'react'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { securedFetchBatchesByFilter, securedFetchBatchesByGrade, securedFetchGrades } from '../../services/BatchService';

import { UnauthorizedError } from '../../services/Errors';
import { ADMIN_USER_TYPE } from '../../services/UserService';
import { authErrorCallbackNavigate, navigateCallbackOptions } from '../../services/AuthenticationService';
import { updateSearchParams } from '../../services/CommonService';
import Dropdown from '../Common/Tailwind/SelectMenus/Dropdown';
import { PlusCircleIcon } from '@heroicons/react/24/outline';

const GradeBatchFilter = ({ showTitle = false, grades, selectedGrade, batches, selectedBatch, setGrades, setSelectedGrade, setBatches, setSelectedBatch, initialGradeId = null, initialBatchId = null, onSelectedBatchChange, user = ADMIN_USER_TYPE, useSearchParamter = false, replaceSearchParams = true, setGradeBatchCreateOpen = null, required = false }) => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const [searchParams, setSearchParams] = useSearchParams();

    const fetchAndSetGrades = async () => {
        const gradesResponse = await securedFetchGrades(navigateCallbackOptions(navigate));
        if (gradesResponse === null) {
            return null;
        }
        return gradesResponse.data;
    };

    useEffect(() => {
        (async () => {
            if (initialGradeId === selectedGrade.id && initialBatchId === selectedBatch.id) {
                return;
            }
            const fetchedGrades = await fetchAndSetGrades();
            if (fetchedGrades === null) {
                return;
            }
            const parsedGrades = fetchedGrades.map((item) => ({ id: item.grade, grade_name: String(item.grade) }));
            setGrades(parsedGrades);
            const initialSelectedGrade = parsedGrades.find((grade) => grade.id === initialGradeId) || {};
            setSelectedGrade(initialSelectedGrade);
            if (initialSelectedGrade.id) {
                const batchesResponse = await securedFetchBatchesByFilter({ grades: [initialSelectedGrade.id] }, navigateCallbackOptions(navigate));
                if (batchesResponse === null) {
                    return;
                }
                setBatches(batchesResponse.data);
                const initialSelectedBatch = batchesResponse.data.find(batch => batch.id === initialBatchId) || {};
                if (initialSelectedBatch.id) {
                    await onSelectedBatchChange({ value: initialSelectedBatch.id, label: initialSelectedBatch.batch_name });
                }
            }
        })();
    }, [initialGradeId, initialBatchId]);

    const onSelectedGradeChange = async (selection) => {
        const gradeId = selection ? selection.value : null;
        if (gradeId) {
            const batchesResponse = await securedFetchBatchesByFilter({ grades: [gradeId] }, navigateCallbackOptions(navigate));
            if (batchesResponse === null) {
                return;
            }
            setBatches(batchesResponse.data);
            setSelectedBatch({});
        } else {
            setBatches([]);
            setSelectedBatch({});
        }
        setSelectedGrade({ id: gradeId, grade_name: selection.label });
    };

    const onSelectedBatchChangeWrapper = async (selection) => {
        if (useSearchParamter) {
            updateSearchParams(setSearchParams, searchParams, { gradeId: selectedGrade.id, batchId: selection.value }, replaceSearchParams);
        }
        await onSelectedBatchChange(selection);
    }

    return (
        <div className="w-full flex flex-col justify-center items-center gap-2 md:gap-4 z-2">

            {showTitle &&
                <h3 className="mt-2 text-lg font-semibold leading-6 text-[var(--SecondaryColor)]">
                    {t('selectGradeBatch')}
                </h3>
            }

            <div className="mt-2 flex flex-col sm:flex-row items-center justify-center gap-4 md:gap-6 lg:gap-8 w-full">

                {/* Select Grade */}
                <Dropdown
                    list={grades}
                    selected={selectedGrade}
                    onSelectionChange={onSelectedGradeChange}
                    label={t('grade')}
                    nameField='grade_name'
                    valueField='id'
                    required={required}
                    className='flex items-center justify-center gap-2 w-40'
                />

                {/* Select Batch */}
                <Dropdown
                    list={batches}
                    selected={selectedBatch}
                    onSelectionChange={onSelectedBatchChangeWrapper}
                    label={t('batch')}
                    nameField='batch_name'
                    valueField='id'
                    required={required}
                    isSearchable={true}
                    isAddable={true}
                    className='flex items-center justify-center gap-2 w-40'
                />

                {setGradeBatchCreateOpen && (
                    <button
                        onClick={() => setGradeBatchCreateOpen(true)}
                        data-tooltip-id="tooltip-create-new-batch"
                        data-tooltip-content="Create New Batch"
                        data-tooltip-place="right"
                    >
                        <PlusCircleIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                    </button>
                )}
            </div>

        </div>
    )
};

export default GradeBatchFilter;