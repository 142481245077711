'use client'

import { useState, useEffect } from 'react'
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next'

const Dropdown = ({
    list,
    selected,
    onSelectionChange,
    label,
    nameField,
    valueField,
    multiple = false,
    isSearchable = false,
    isAddable = false,
    added,
    onSelectionAdd,
    required = false,
    className = '',
    placeholder = "Select..."
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredList, setFilteredList] = useState(list);

    const { t } = useTranslation();

    useEffect(() => {
        if (isSearchable) {
            setFilteredList(list.filter(item =>
                item[nameField].toLowerCase().includes(searchTerm.toLowerCase())
            ));
        } else {
            setFilteredList(list);
        }
    }, [searchTerm, list, nameField, isSearchable]);

    const isSelected = (value) => {
        if (multiple) {
            return selected.some(item => item[valueField] === value);
        }
        return selected && selected[valueField] === value;
    };

    const handleSelectionChange = (selectedItem) => {

        if (multiple) {
            onSelectionChange(selectedItem.map(selection => ({ value: selection[valueField], label: selection[nameField] })));
        } else {
            onSelectionChange({ value: selectedItem[valueField], label: selectedItem[nameField] });
        }
    };

    const displayValue = multiple
        ? selected.map(item => item[nameField]).join(', ')
        : selected ? selected[nameField] : '';

    return (
        <div className={className}>
            <Listbox
                value={selected}
                onChange={handleSelectionChange}
                multiple={multiple}
            >
                <Label className="block text-sm font-medium leading-6 text-gray-900 whitespace-nowrap">{label}</Label>
                <div className="relative w-full">
                    <ListboxButton className="relative w-full overflow-hidden cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-[var(--PrimaryColor)] leading-6 text-sm font-medium">
                        <span className="block truncate text-[var(--textColor)]">{t(displayValue) || placeholder}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                        </span>
                    </ListboxButton>

                    <ListboxOptions
                        transition
                        className="absolute w-full z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    >
                        {isSearchable && (
                            <div className="px-3 py-2">
                                <input
                                    type="text"
                                    className="w-full border-gray-300 rounded-md text-sm font-medium"
                                    placeholder="Search..."
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </div>
                        )}

                        {filteredList.map((item) => (
                            <ListboxOption
                                key={item[valueField]}
                                value={item}
                                className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-10 pr-4 text-sm font-medium ${active ? 'bg-[var(--PrimaryColor)] text-white' : 'text-gray-900'
                                    }`
                                }
                            >
                                {({ selected, active }) => (
                                    <>
                                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                            {t(item[nameField])}
                                        </span>
                                        {selected && (
                                            <span
                                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-[var(--PrimaryColor)]'
                                                    }`}
                                            >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        )}
                                    </>
                                )}
                            </ListboxOption>
                        ))}

                        {isAddable && (
                            <div className="px-3 py-2">
                                <input
                                    type="text"
                                    className="w-full border-gray-300 rounded-md"
                                    placeholder="Add..."
                                    value={added}
                                    onChange={e => onSelectionAdd(e.target.value)}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </div>
                        )}
                    </ListboxOptions>
                </div>
            </Listbox>
            {required && <input type="hidden" value={selected} required />}
        </div>
    )
}

export default Dropdown;