import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import Modal from 'react-modal';

import './testSubject.css'

import { useTest } from '../TestContext';
import SelectionDropdown from '../../Common/SelectionDropdown';
import { UnauthorizedError } from '../../../services/Errors';
import { securedCreateTestSections, securedCreateTestSubjects } from '../../../services/TestService';
import { securedFetchSubjects } from '../../../services/SyllabusService';
import { ADMIN_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';

import { AiFillCloseCircle } from "react-icons/ai";
import { BiMessageSquareAdd } from 'react-icons/bi';
import { render } from '@fullcalendar/core/preact.js';

Modal.setAppElement('#root');  // This is important for accessibility reasons

const AutoTestSubjectCreate = ({ isOpen, onRequestClose, allowedSubjects, onAddSubject = null, user = ADMIN_USER_TYPE }) => {
    const [selectedSubject, setSelectedSubject] = useState({});

    const onSelectedSubjectChange = (selection) => {
        const subjectId = selection ? selection.value : null;
        if (subjectId) {
            setSelectedSubject({ id: subjectId, subject_name: selection.label });
        }
    }

    useEffect(() => {
        if (!isOpen) {
            return;
        }
        const allowedSubjectIds = allowedSubjects.map(subject => subject.id);
        if (selectedSubject.id && allowedSubjectIds.includes(selectedSubject.id)) {
            return;
        }
        setSelectedSubject({});
    }, [isOpen]);

    const handleSubmit = () => {
        onAddSubject(selectedSubject);
        onRequestClose();
    }

    const renderTestSubjectCreate = () => {
        return (
            <>
                <h3 className="modalTitle">Add Test Subject</h3>
                <SelectionDropdown className="subjectCreateSelectionDropdown" name="Subject" itemList={allowedSubjects} selectedItem={selectedSubject} valueField={'id'} nameField={'subject_name'} onSelectionChange={onSelectedSubjectChange} required={true} />
                <button className="btn flex" onClick={handleSubmit}>
                    Add
                    <BiMessageSquareAdd className="icon" />
                </button>
                <AiFillCloseCircle className="icon" id='close' onClick={onRequestClose} />
            </>
        )
    }

    return (
        <Modal overlayClassName="customModal" className="testSubjectModal flex" isOpen={isOpen} onRequestClose={onRequestClose}>

            {renderTestSubjectCreate()}
        </Modal>
    );
}

export default AutoTestSubjectCreate;
