import React, { useEffect, useState } from 'react';

import { ADMIN_USER_TYPE, TEACHER_USER_TYPE, STUDENT_USER_TYPE } from '../../services/UserService';

import DashboardLectureInfo from './DashboardLectureInfo';
import StudentQuestionRecommendation from '../Recommendation/StudentQuestionRecommendation';

const DashboardMain = ({ selectedTab, user = ADMIN_USER_TYPE }) => {

    if (selectedTab === null) {
        return;
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            {/* className="flex-1 p-6 overflow-y-auto bg-white"> */}
            {selectedTab === 'lectures' && (
                <DashboardLectureInfo user={user} />
            )}

            {selectedTab === 'jeetaQuest' && (
                <StudentQuestionRecommendation user={user} />
            )}
        </div>
    );
};

export default DashboardMain;
