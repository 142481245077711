import React from 'react';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { useTranslation } from "react-i18next";

import { ADMIN_USER_TYPE } from '../../services/UserService';

import Spinner from '../Common/Tailwind/Spinner';
import i18n from '../../multi-language/i18n';

const TestTable = ({ user = ADMIN_USER_TYPE, tests, onTestSelectionChange, convertDurationFormat }) => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    if (tests === null) {
        return <Spinner />;
    }

    return (

        <div className="w-full h-full flex flex-col items-center gap-2 px-4 text-[var(--textColor)]">
            <div class="relative w-full overflow-x-auto overflow-y-auto scrollbar-thin">

                <table className="w-full text-sm">
                    <thead>
                        <tr className="bg-[var(--SecondaryBkg)] text-[var(--textColor)] text-center align-middle">
                            <th className="p-1 leading-relaxed">{t('status')}</th>
                            <th className="p-1 leading-relaxed">{t('id')}</th>
                            <th className="p-1 leading-relaxed">{t('name')}</th>
                            <th className="p-1 leading-relaxed">{t('type')}</th>
                            <th className="p-1 leading-relaxed">{t('date')}</th>
                            <th className="p-1 leading-relaxed">{t('time')}</th>
                            <th className="p-1 leading-relaxed">{t('duration')}</th>
                            {/* <th>Remaining Time</th> */}
                            {/* <th>...</th> */}
                        </tr>
                    </thead>

                    <tbody>
                        {tests.length > 0 && tests.map(test => (
                            <tr key={test.id}
                                onClick={() => onTestSelectionChange(test.id)}
                                className="hover:font-semibold hover:bg-gray-100 cursor-pointer">
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {test.status === "NOT-STARTED" ? (
                                        <p className={'bg-green-100 text-green-700 rounded-md px-1 py-1 text-sm font-medium'} >
                                            {t('upcomingCapital')}
                                        </p>
                                    ) : (test.status === "FINISHED" ?
                                        <p className={'flex justify-center items-center gap-1 bg-blue-100 text-blue-700 rounded-md px-1 py-1 text-sm font-medium'}>
                                            {t('finishedCapital')}
                                        </p>
                                        :
                                        <p className={'bg-red-100 text-red-700 rounded-md px-3 py-2 text-sm font-medium'}>
                                            {t('startedCapital')}
                                        </p>
                                    )}
                                </td>
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {test.id}
                                </td>
                                <td className="text-left py-2 px-4 border-b-2 border-gray-200">
                                    {test.test_name}
                                </td>
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {test.test_type.toUpperCase()}
                                </td>
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {test.test_start_time ? moment(test.test_start_time).locale(i18n.language).format(t('DDslMMslYYYY')) : null}
                                </td>
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {test.test_start_time ? moment(test.test_start_time).locale(i18n.language).format(t('hhcommA')) : null}
                                </td>
                                <td className="text-center py-2 px-4 border-b-2 border-gray-200">
                                    {test.test_duration_minutes ? convertDurationFormat(test.test_duration_minutes) : null}
                                </td>
                                {/* <td></td> */}
                                {/* <td></td> */}
                            </tr>
                        ))}
                    </tbody>
                </table >
            </div >
        </div >
    );
};
export default TestTable;
