import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

import ForgotPassword from './ForgotPassword';

import { ArrowRightEndOnRectangleIcon, EnvelopeIcon, LockClosedIcon } from '@heroicons/react/24/outline';

const LoginForm = ({ handleLogin, handleRegister, showLogIn = false }) => {

    const { t } = useTranslation();
    const [credentials, setCredentials] = useState({
        username: "",
        password: ""
    });

    const handleCredentialsChange = (e) => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value
        });
    };

    const [isForgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);

    return (
        <form className="flex flex-col w-72 md:w-96 py-2 px-4 justify-center items-center">
            <div className="w-full flex flex-col gap-2">
                {/* Email */}
                <div className="flex items-center gap-2 col-span-2">
                    <EnvelopeIcon className="w-6 h-6 text-[var(--PrimaryColor)]" />

                    <input
                        id="email-address"
                        name="username"
                        type="email"
                        required
                        value={credentials.username}
                        onChange={handleCredentialsChange}
                        placeholder={t('emailAddress')}
                        autoComplete="email"
                        aria-label="Email address"
                        className="block w-full rounded-t-md bg-white px-3 py-1.5 text-sm md:text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400
                        focus:relative focus:outline-2 focus:-outline-offset-2 focus:outline-[var(--PrimaryColor)] sm:text-sm/6"
                    />
                </div>

                {/* Password */}
                <div className="flex items-center gap-2 -mt-px">
                    <LockClosedIcon className="w-6 h-6 text-[var(--PrimaryColor)]" />

                    <input
                        id="password"
                        name="password"
                        type="password"
                        required
                        value={credentials.password}
                        onChange={handleCredentialsChange}
                        placeholder={t('password')}
                        autoComplete="current-password"
                        aria-label="Password"
                        className="block w-full rounded-b-md bg-white px-3 py-1.5 text-sm md:text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400
                        focus:relative focus:outline-2 focus:-outline-offset-2 focus:outline-[var(--PrimaryColor)] sm:text-sm/6"
                    />
                </div>

                {/* Remember/Forgot Password */}
                <div className="flex items-center justify-between">
                    <div className="flex gap-1 md:gap-2">
                        <div className="flex h-6 shrink-0 items-center">
                            <div className="group grid size-4 grid-cols-1">
                                <input
                                    id="remember-me"
                                    name="remember-me"
                                    type="checkbox"
                                    className="col-start-1 row-start-1 appearance-none rounded border border-gray-300 bg-white
                                    checked:border-[var(--SecondaryColor)] checked:bg-[var(--SecondaryColor)]
                                    indeterminate:border-indigo-600 indeterminate:bg-indigo-600
                                    focus-visible:outline focus-visible:outline-2
                                    focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                                />
                                <svg
                                    fill="none"
                                    viewBox="0 0 14 14"
                                    className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
                                >
                                    <path
                                        d="M3 8L6 11L11 3.5"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="opacity-0 group-has-[:checked]:opacity-100"
                                    />
                                    <path
                                        d="M3 7H11"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="opacity-0 group-has-[:indeterminate]:opacity-100"
                                    />
                                </svg>
                            </div>
                        </div>
                        <label htmlFor="remember-me" className="block text-xs/6 md:text-sm/6 text-gray-900">
                            Remember me
                        </label>
                    </div>

                    <div className="text-xs/6 md:text-sm/6">
                        <label className="font-semibold text-[var(--tiger-lilly)] hover:text-[var(--SecondaryColor)] hover:cursor-pointer"
                            onClick={() => setForgotPasswordModalOpen(true)}>
                            {t('forgotPassword')}?
                        </label>
                        <ForgotPassword
                            isOpen={isForgotPasswordModalOpen}
                            onRequestClose={() => { setForgotPasswordModalOpen(false) }}
                            initEmail={credentials.username} />
                    </div>
                </div>

                <div>
                    <button
                        type="submit"
                        className="flex w-full justify-center items-center gap-1 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-xs/6 md:text-sm/6 font-semibold text-white hover:bg-[var(--SecondaryColor)] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={(event) => handleLogin(event, credentials)}
                    >
                        <ArrowRightEndOnRectangleIcon className="w-4 h-4" />
                        Sign in
                    </button>
                </div>
            </div>

        </form >
    )
};

export default LoginForm;
