import React from 'react'

import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer';

import { TEACHER_USER_TYPE } from '../../services/UserService';
import { TeacherNavItems } from './TeacherNavItems';
import { useParams } from 'react-router-dom';
import SlotView from '../../Components/Slots/SlotView';

const TeacherSlots = () => {
    const { slotId } = useParams();
    return (
        <div className="flex flex-col min-h-screen">
            <Navbar navItems={TeacherNavItems} user={TEACHER_USER_TYPE} />
            <SlotView slotId={parseInt(slotId)} user={TEACHER_USER_TYPE} />
            <Footer user={TEACHER_USER_TYPE} />
        </div>
    )
}

export default TeacherSlots;
