import React, { useState, useReducer, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import '../questionModal.css'

import ImagePreview from '../../../Common/ImagePreview'
import ImageUpload from '../../../Common/ImageUpload'
import QuestionTypeSingleChoice from './QuestionTypeSingleChoice';
import QuestionTypeMultiChoice from './QuestionTypeMultiChoice';
import { QuestionFormContext } from '../QuestionFormContext';
import SelectionDropdown from '../../../Common/SelectionDropdown';
import { QUESTION_DIFFICULTIES, securedPostParagraph } from '../../../../services/QuestionService';
import { ADMIN_USER_TYPE } from '../../../../services/UserService';
import { navigateCallbackOptions } from '../../../../services/AuthenticationService';

import { BsCloudUpload } from 'react-icons/bs';
import { RiDeleteBin5Line } from 'react-icons/ri'
import { MdAdd } from 'react-icons/md';
import { securedUpdateParagraph } from '../../../../services/QuestionService';
import QuestionTypeSubjective from './QuestionTypeSubjective';
import { replaceImagesInObject } from '../../../../services/CommonService';

const questionReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_QUESTION':
            return [...state, action.payload];
        case 'REMOVE_QUESTION':
            return state.filter((question) => question.id !== action.payload);
        case 'UPDATE_QUESTION':
            return state.map((question) =>
                question.id === action.payload.id ? { ...question, ...action.payload } : question
            );
        case 'RESET':
            return [];
        default:
            return state;
    }
};

const QuestionTypeParagraph = ({ inputParagraphData = { id: null }, isImageBased, topics, tags, onRequestClose = null, onAddQuestion = null, isQuestionDataValid, setIsSaving, user = ADMIN_USER_TYPE }) => {
    const [paragraph, setParagraph] = useState('')
    const [paragraphImages, setParagraphImages] = useState([])
    const [questions, dispatch] = useReducer(questionReducer, []);
    const [difficulty, setDifficulty] = useState(QUESTION_DIFFICULTIES[0]);
    const [questionImageFileNames, setQuestionImageFileNames] = useState([]);

    const navigate = useNavigate();

    const resetState = () => {
        setParagraph('');
        setParagraphImages([]);
        dispatch({ type: 'RESET' });
    }

    const getInputQuestionData = (questionId) => {
        if (inputParagraphData.id === null) {
            return { id: null };
        }
        const inputQuestion = inputParagraphData.questions.find(question => question.question_id === questionId);
        if (!inputQuestion) {
            return { id: null };
        }
        return inputQuestion.question;
    }

    useEffect(() => {
        if (inputParagraphData.id === null) {
            return;
        }
        setParagraph(inputParagraphData.paragraph_text);
        dispatch({ type: 'RESET' });
        inputParagraphData.questions.forEach(question => dispatch({ type: 'ADD_QUESTION', payload: { id: question.question_id, type: question.question.question_subtype } }));
        (async () => {
            setParagraphImages((await replaceImagesInObject(inputParagraphData.images, navigate)).map(image => image.image.url), navigate);
        })();

        setDifficulty(QUESTION_DIFFICULTIES.find(difficulty => difficulty.id === inputParagraphData.paragraph_difficulty));

    }, [inputParagraphData.id]);

    const addQuestion = (type) => {
        const newQuestion = {
            id: Math.random().toString(),
            type: type,
            data: {}
        };

        dispatch({ type: 'ADD_QUESTION', payload: newQuestion });
    };

    const removeQuestion = (id) => {
        dispatch({ type: 'REMOVE_QUESTION', payload: id });
    };

    const onTextChange = (inputText) => (event) => {
        const { value } = event.target;
        inputText(value);
    };

    // const onDifficultyChange = (selection) => {
    //     setDifficulty({ id: selection.value, name: selection.label });
    // }

    const handleDifficultyClick = (selection) => {
        setDifficulty({ id: selection.id, name: selection.name });
    }

    const areQuestionsValid = () => {
        return questions.every(question => isSingleQuestionValid(question));
    }

    const isSingleQuestionValid = (question) => {
        if (isImageBased) {
            if (question.type === 'single choice') {
                return question.data.questionImages.length > 0 && question.data.answerInputs.every(str => str.value.trim() !== '');
            }
            if (question.type === 'multi choice') {
                return question.data.questionImages.length > 0 && question.data.answerInputs.every(str => str.value.trim() !== '') && question.data.selectedAnswers.some(selectedAnswer => (selectedAnswer));
            }
            if (question.type === 'numerical') {
                return question.data.questionImages.length > 0 && question.data.answer.min_value != null;
            }
            return question.data.questionImages.length > 0 && question.data.answer.value.length > 0;
        }
        if (question.type === 'single choice') {
            return question.data.question.length > 0 && question.data.answerInputs.every(str => str.value.trim() !== '');
        }
        if (question.type === 'multi choice') {
            return question.data.question.length > 0 && question.data.answerInputs.every(str => str.value.trim() !== '') && question.data.selectedAnswers.some(selectedAnswer => (selectedAnswer));
        }
        if (question.type === 'numerical') {
            return question.data.question.length > 0 && question.data.answer.min_value != null;
        }
        return question.data.question.length > 0 && question.data.answer.value.length > 0;
    }

    const isInternalDataInvalid = () => {
        if (!isImageBased) {
            return !isQuestionDataValid() || !areQuestionsValid() || paragraph.length === 0 || questions.length === 0;
        }
        return !isQuestionDataValid() || !areQuestionsValid() || paragraphImages.length === 0 || questions.length === 0;
    }

    const getParagraphQuestionId = (question) => {
        if (inputParagraphData.id === null) {
            return null;
        }
        const initialQuestion = inputParagraphData.questions.find(paragraphQuestion => paragraphQuestion.question_id === question.id);
        if (!initialQuestion) {
            return null;
        }
        return initialQuestion.id;
    }

    const handleSubmit = async () => {
        if (isInternalDataInvalid()) {
            return;
        }
        setIsSaving(true);

        let questionsWithIds;
        if (inputParagraphData.id !== null) {
            questionsWithIds = questions.map(question => ({ ...question, paragraph_question_id: getParagraphQuestionId(question) }));
        } else {
            questionsWithIds = questions;
        }
        const paragraphData = {
            paragraph: !isImageBased ? paragraph : '',
            questions: questionsWithIds,
            topics: topics,
            tags: tags,
            paragraphImages: paragraphImages,
            difficulty: difficulty.id
        };
        let response;
        if (inputParagraphData.id !== null) {
            response = await securedUpdateParagraph(inputParagraphData.id, paragraphData, navigateCallbackOptions(navigate));
        } else {
            response = await securedPostParagraph(paragraphData, navigateCallbackOptions(navigate));
        }
        if (response === null) {
            setIsSaving(false);
            return;
        }
        if (onAddQuestion) {
            onAddQuestion('paragraph', response.paragraph_id);
        }
        resetState();
        setIsSaving(false);
        if (onRequestClose != null) {
            onRequestClose();
        }
    };

    const renderSingleQuestionCreate = (question) => {
        if (question.type === 'single choice') {
            return <QuestionTypeSingleChoice inputQuestionData={getInputQuestionData(question.id)} id={question.id} isImageBased={isImageBased} topics={topics} tags={tags} overallDifficulty={difficulty} hasSubmit={false} />;
        }
        if (question.type === 'multi choice') {
            return <QuestionTypeMultiChoice inputQuestionData={getInputQuestionData(question.id)} id={question.id} isImageBased={isImageBased} topics={topics} tags={tags} overallDifficulty={difficulty} hasSubmit={false} />
        }
        return <QuestionTypeSubjective type="numerical" inputQuestionData={getInputQuestionData(question.id)} id={question.id} isImageBased={isImageBased} topics={topics} tags={tags} overallDifficulty={difficulty} hasSubmit={false} />;
    }

    const renderDeleteButton = (question) => {
        if (inputParagraphData.id !== null && inputParagraphData.questions.find(paragraphQuestion => paragraphQuestion.question_id === question.id)) {
            return (<></>);
        }
        return (<button className="delete-btn" onClick={() => removeQuestion(question.id)}><RiDeleteBin5Line className="icon" /> </button>);
    }

    return (

        <div className="paragraph flex">

            <QuestionFormContext.Provider value={{ questions, dispatch }}>

                {/* Paragraph Input */}
                {!isImageBased && (
                    <div className="questionTextInput flex">
                        <label className="title">Paragraph</label>
                        <textarea className="text" placeholder="Enter..." onChange={onTextChange(setParagraph)} value={paragraph} rows="4" required />
                    </div>)}

                {/* Image Input */}
                <ImageUpload title='Paragraph Image(s)' images={paragraphImages} selectImages={setParagraphImages} label="paragraph" maxSize={isImageBased ? 1 : null} setImageFileNames={setQuestionImageFileNames} />
                <ImagePreview images={paragraphImages} selectImages={setParagraphImages} imageFileNames={questionImageFileNames} />

                {/* Difficulty Selection */}
                <div className="questionDifficultyInput flex">
                    <label className="title">Difficulty</label>

                    <div className="difficulty-btns flex">
                        {QUESTION_DIFFICULTIES.map((difficulty_option) => (
                            <button
                                key={difficulty_option}
                                className={`difficulty-btn ${difficulty.id === difficulty_option.id ? 'selected' : ''} flex`}
                                onClick={() => handleDifficultyClick(difficulty_option)}
                            >
                                {difficulty_option.name}
                            </button>
                        ))}
                    </div>

                </div>
                {/* <SelectionDropdown className="questionSelectionDropdown" name="Difficulty" itemList={QUESTION_DIFFICULTIES} selectedItem={difficulty} onSelectionChange={onDifficultyChange} nameField='name' valueField='id' required={true} /> */}

                {questions.map((question, idx) => (
                    <div className="subQuestion flex" key={question.id}>
                        {renderSingleQuestionCreate(question)}
                        {renderDeleteButton(question)}
                        <p>{idx + 1}</p>
                    </div>
                ))}

                <div className="add-question-btns grid">
                    <button className='add-btn flex' onClick={() => addQuestion('single choice')}><MdAdd className="icon" /> Single Choice Question</button>
                    <button className='add-btn flex' onClick={() => addQuestion('multi choice')}><MdAdd className="icon" /> Multiple Choice Question</button>
                    <button className='add-btn flex' onClick={() => addQuestion('numerical')}><MdAdd className="icon" /> Numerical Question</button>
                </div>
                <button className='btn flex' id='submit' onClick={handleSubmit}>
                    SUBMIT <BsCloudUpload className="icon" />
                </button>

            </QuestionFormContext.Provider>

        </div>

    );
};

export default QuestionTypeParagraph;