import React, { useState } from 'react';

import { DevicePhoneMobileIcon, EnvelopeIcon, UserIcon } from '@heroicons/react/24/outline';

const BaseRegisterForm = ({ email, setEmail, contactNumber, setContactNumber, fullName, setFullName }) => {

    const onTextChange = (textSetter) => (event) => {
        const { value } = event.target;
        textSetter(value);
    };

    return (

        <>
            {/* Full Name */}
            <div className="flex items-center gap-2">
                <UserIcon className="w-6 h-6 text-[var(--PrimaryColor)]" />
                <input
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[var(--PrimaryColor)] sm:text-sm sm:leading-6"
                    type="fullName"
                    name="fullName"
                    value={fullName}
                    onChange={onTextChange(setFullName)}
                    placeholder='Full Name'
                    required />
            </div>

            {/* Email */}
            <div className="flex items-center gap-2">
                <EnvelopeIcon className="w-6 h-6 text-[var(--PrimaryColor)]" />
                <input
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[var(--PrimaryColor)] sm:text-sm sm:leading-6"
                    type="email"
                    name="email"
                    value={email}
                    onChange={onTextChange(setEmail)}
                    placeholder='E-mail Address'
                    required />
            </div>

            {/* Phone Number */}
            <div className="flex items-center gap-2">
                <DevicePhoneMobileIcon className="w-6 h-6 text-[var(--PrimaryColor)]" />
                <input
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[var(--PrimaryColor)] sm:text-sm sm:leading-6"
                    type="contactNumber"
                    name="contactNumber"
                    value={contactNumber}
                    onChange={onTextChange(setContactNumber)}
                    placeholder='Contact Number'
                    required />
            </div>
        </>

    )
};

export default BaseRegisterForm;
