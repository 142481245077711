import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'

import Modal from "react-modal";
import moment from 'moment';

import './batches.css'

import { ADMIN_USER_TYPE, securedFetchUsers } from '../../services/UserService';
import { securedFetchSubjects } from '../../services/SyllabusService';
import { CLASS_TYPES, ONLINE_CLASS_TYPE, REPEAT_TYPES, securedCreateSchedule, securedFetchLectureHalls } from '../../services/TimetableService';
import { navigateCallbackOptions } from '../../services/AuthenticationService';

import { AiFillCloseCircle } from "react-icons/ai";
import { MdAddCircleOutline } from "react-icons/md";
import { useAuth } from "../Authentication/AuthContext";
import { securedFetchBatchesByFilter } from "../../services/BatchService";
import SelectionDropdownMultiple from "../Common/SelectionDropdownMultiple";
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import Dropdown from "../Common/Tailwind/SelectMenus/Dropdown";
import Spinner from "../Common/Tailwind/Spinner";

const BatchScheduleCreate = ({ isOpen = false, onRequestClose = null, startInput, endInput, batchId, grade, onScheduleCreate = null, user = ADMIN_USER_TYPE }) => {

    const { config } = useAuth();
    const [subjects, setSubjects] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState({});
    const [teachers, setTeachers] = useState([]);
    const [selectedTeacher, setSelectedTeacher] = useState({});
    const [halls, setHalls] = useState([]);
    const [selectedHall, setSelectedHall] = useState({});
    const [selectedRepeatType, setSelectedRepeatType] = useState(null);
    const [selectedClassType, setSelectedClassType] = useState(config.DEFAULT_TIMETABLE_CLASS_TYPE ? CLASS_TYPES.find(type => type.id === config.DEFAULT_TIMETABLE_CLASS_TYPE) || CLASS_TYPES[0] : CLASS_TYPES[0]);
    const [selectedScheduleId, setSelectedScheduleId] = useState();
    const [batches, setBatches] = useState([]);
    const [selectedBatches, setSelectedBatches] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const navigate = useNavigate();

    const makeFilter = (batchId) => {
        const filter = {
            batch_id: batchId
        }
        return filter;
    }

    const onChange = (setter) => (event) => {
        setter(event.target.value);
    }

    const fetchSubjects = async () => {
        const subjectsResponse = await securedFetchSubjects(navigateCallbackOptions(navigate));
        if (subjectsResponse === null) {
            return null;
        }
        return subjectsResponse.data;
    };

    const fetchTeachers = async (filter) => {
        const teachersResponse = await securedFetchUsers('teachers', filter, navigateCallbackOptions(navigate));
        if (teachersResponse === null) {
            return null;
        }
        return teachersResponse.data.map(teacher => ({ id: teacher.id, teacher_name: teacher.user_data.first_name + ' ' + teacher.user_data.last_name }));
    }

    const fetchHalls = async (filter) => {
        const hallsResponse = await securedFetchLectureHalls(filter, navigateCallbackOptions(navigate))
        if (hallsResponse === null) {
            return null;
        }
        return hallsResponse.data;
    }

    const fetchBatches = async (filter) => {
        const batchesResponse = await securedFetchBatchesByFilter(filter, navigateCallbackOptions(navigate))
        if (batchesResponse === null) {
            return null;
        }

        return batchesResponse.data.filter(batch => batch.id !== batchId);
    }

    useEffect(() => {
        (async () => {
            const subjectsResponse = await fetchSubjects();
            if (subjectsResponse === null) {
                return;
            }
            setSubjects(subjectsResponse);
            const teachersResponse = await fetchTeachers({ subject_ids: subjectsResponse.map(subject => parseInt(subject.id)) });
            if (teachersResponse === null) {
                return;
            }
            setTeachers(teachersResponse);
            const hallsResponse = await fetchHalls(null);
            if (hallsResponse === null) {
                return;
            }
            setHalls(hallsResponse);
            const batchesResponse = await fetchBatches({ grades: [grade] });
            if (batchesResponse === null) {
                return;
            }
            setBatches(batchesResponse);
        })();
    }, []);

    useEffect(() => {
        setSelectedTeacher({});
        setSelectedSubject({});
        setSelectedHall({});
        setSelectedRepeatType({});
        setSelectedBatches([]);
        setStartDate(moment(startInput).format('YYYY-MM-DD'));
        setStartTime(moment(startInput).format('HH:mm:ss'));
        setEndDate(moment(endInput).format('YYYY-MM-DD'));
        setEndTime(moment(endInput).format('HH:mm:ss'));
    }, [startInput, endInput]);

    const onSelectedSubjectChange = async (selection) => {
        const subjectId = selection ? selection.value : null;
        if (subjectId) {
            setSelectedSubject({ id: subjectId, subject_name: selection.label });
            const teachersResponse = await fetchTeachers({ subject_ids: [subjectId] });
            if (teachersResponse === null) {
                return;
            }
            setTeachers(teachersResponse);
            if (teachersResponse.length > 0) {
                setSelectedTeacher(teachersResponse[0]);
            }
        }
    };

    const onSelectedTeacherChange = async (selection) => {
        const teacherId = selection ? selection.value : null;
        if (teacherId) {
            setSelectedTeacher({ id: teacherId, teacher_name: selection.label });
        }
    };

    const onSelectedHallChange = async (selection) => {
        const hallId = selection ? selection.value : null;
        console.log(selection);
        if (hallId) {
            setSelectedHall({ id: hallId, lecture_hall_name: selection.label });
        }
    };

    const onSelectedRepeatTypeChange = async (selection) => {
        const repeatTypeId = selection ? selection.value : null;
        if (repeatTypeId) {
            setSelectedRepeatType({ id: repeatTypeId, name: selection.label });
        }
    };

    const onSelectedClassTypeChange = async (selection) => {
        const classTypeId = selection ? selection.value : null;
        console.log(selection)
        if (classTypeId) {
            setSelectedClassType({ id: classTypeId, name: selection.label });
        }
    };

    const onSelectedBatchesChange = async (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const newBatches = batches.filter(batch => selectedIds.includes(batch.id));
        setSelectedBatches(newBatches);
    };

    const onSubmit = async () => {
        setIsSaving(true);
        const data = {
            start_time: startTime,
            end_time: endTime,
            start_date: startDate,
            end_date: endDate,
            batches: [{ batch_id: batchId }, ...selectedBatches.map(batch => ({ batch_id: batch.id }))],
            subject_id: selectedSubject.id,
            teacher_id: selectedTeacher.id,
            repeat_type: selectedRepeatType.id,
            class_type: selectedClassType.id,
            lecture_hall_id: selectedHall.id,
        }
        const response = await securedCreateSchedule(data, navigateCallbackOptions(navigate));
        onRequestClose();
        if (response !== null && onScheduleCreate) {
            await onScheduleCreate();
        }
        setIsSaving(false);
    };

    const renderScheduleCreate = () => {
        if (isSaving) {
            return <Spinner />;
        }
        return (
            <>
                <div>
                    {/* <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                        <RiLockPasswordLine aria-hidden="true" className="h-6 w-6 text-green-600" />
                    </div> */}
                    <div className="mt-3 text-center sm:mt-5">
                        <DialogTitle as="h3" className="text-center text-xl font-semibold leading-6 text-[var(--SecondaryColor)]">
                            Creat New Schedule
                        </DialogTitle>

                        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                            <form className="flex flex-col gap-4 justify-center">

                                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                                    <div>
                                        <label htmlFor="subject" className="block text-sm font-semibold leading-6 text-gray-900">
                                            Subject
                                        </label>
                                        <Dropdown
                                            list={subjects}
                                            selected={selectedSubject}
                                            onSelectionChange={onSelectedSubjectChange}
                                            nameField='subject_name'
                                            valueField='id'
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="teacher" className="block text-sm font-semibold leading-6 text-gray-900">
                                            Teacher
                                        </label>
                                        <div className="mt-2">
                                            <Dropdown
                                                list={teachers}
                                                selected={selectedTeacher}
                                                onSelectionChange={onSelectedTeacherChange}
                                                nameField='teacher_name'
                                                valueField='id'
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="repeat-type" className="block text-sm font-semibold leading-6 text-gray-900">
                                            Repeat Type
                                        </label>
                                        <div className="mt-2">
                                            <Dropdown
                                                list={REPEAT_TYPES}
                                                selected={selectedRepeatType}
                                                onSelectionChange={onSelectedRepeatTypeChange}
                                                nameField='name'
                                                valueField='id'
                                            // required={true}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="class-type" className="block text-sm font-semibold leading-6 text-gray-900">
                                            Class Type
                                        </label>
                                        <div className="mt-2">
                                            <Dropdown
                                                list={CLASS_TYPES}
                                                selected={selectedClassType}
                                                onSelectionChange={onSelectedClassTypeChange}
                                                nameField='name'
                                                valueField='id'
                                            // required={true}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="class-type" className="block text-sm font-semibold leading-6 text-gray-900">
                                            Batches
                                        </label>
                                        <div className="mt-2">
                                            <Dropdown
                                                list={batches}
                                                selected={selectedBatches}
                                                onSelectionChange={onSelectedBatchesChange}
                                                nameField='batch_name'
                                                valueField='id'
                                                multiple={true}
                                            // required={true}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="startTime" className="block text-sm font-semibold leading-6 text-gray-900">
                                            Start Time
                                        </label>
                                        <input
                                            className="mt-1 block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            type="time"
                                            id="startTime"
                                            name="startTime"
                                            value={startTime}
                                            min="09:00"
                                            max="24:00"
                                            onChange={onChange(setStartTime)}
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="endTime" className="block text-sm font-semibold leading-6 text-gray-900">
                                            End Time
                                        </label>
                                        <input
                                            className="mt-1 block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            type="time"
                                            id="endTime"
                                            name="endTime"
                                            value={endTime}
                                            min="09:00"
                                            max="24:00"
                                            onChange={onChange(setEndTime)}
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="startDate" className="block text-sm font-semibold leading-6 text-gray-900">
                                            Start Date
                                        </label>
                                        <input
                                            className="min-w-0 flex-auto rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            type="date"
                                            id="startDate"
                                            name="startDate"
                                            value={startDate}
                                            onChange={onChange(setStartDate)}
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="endDate" className="block text-sm font-semibold leading-6 text-gray-900">
                                            End Date</label>
                                        <input
                                            className="min-w-0 flex-auto rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            type="date"
                                            id="endDate"
                                            name="endDate"
                                            value={endDate}
                                            onChange={onChange(setEndDate)}
                                            required
                                        />
                                    </div>
                                    {selectedClassType.id !== ONLINE_CLASS_TYPE && (
                                        <div>
                                            <label htmlFor="class-type" className="block text-sm font-semibold leading-6 text-gray-900">
                                                Lecture Hall
                                            </label>
                                            <Dropdown
                                                onSelectionChange={onSelectedHallChange}
                                                selected={selectedHall}
                                                list={halls}
                                                nameField='lecture_hall_name'
                                                valueField='id'
                                            />
                                        </div>
                                    )}
                                </div>

                                <div>
                                    <button
                                        onClick={onSubmit}
                                        className="flex w-full justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[var(--PrimaryColor)]"
                                    >
                                        <span>Create</span>
                                        <PlusCircleIcon className="h-5 w-5" />
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div >
                </div >
            </>

            // <>
            //     <div className="batchScheduleCreate flex">

            //         <div className="batchInfoInput flex">


            //             <SelectionDropdown className="batchSelectionDropdown" name="Subject" onSelectionChange={onSelectedSubjectChange} selectedItem={selectedSubject} itemList={subjects} nameField='subject_name' valueField='id' required={true} />
            //             <SelectionDropdown className="batchSelectionDropdown" name="Teacher" onSelectionChange={onSelectedTeacherChange} selectedItem={selectedTeacher} itemList={teachers} nameField='teacher_name' valueField='id' required={true} />
            //             <SelectionDropdown className="batchSelectionDropdown" name="Repeat Type" onSelectionChange={onSelectedRepeatTypeChange} selectedItem={selectedRepeatType} itemList={REPEAT_TYPES} nameField='name' valueField='id' required={true} />
            //             <SelectionDropdown className="batchSelectionDropdown" name="Class Type" onSelectionChange={onSelectedClassTypeChange} selectedItem={selectedClassType} itemList={CLASS_TYPES} nameField='name' valueField='id' required={true} />
            //             <SelectionDropdownMultiple className="batchSelectionDropdown" name="Other Batches" onSelectionToggle={onSelectedBatchesChange} selectedItems={selectedBatches} itemList={batches} nameField='batch_name' valueField='id' />

            //             <div className="timeDateInput flex">
            //                 <div className="timeInput flex">
            //                     <label className="title">Start Time</label>
            //                     <input
            //                         className="mt-1 block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            //                         type="time"
            //                         id="startTime"
            //                         name="startTime"
            //                         value={startTime}
            //                         min="09:00"
            //                         max="24:00"
            //                         onChange={onChange(setStartTime)}
            //                         required
            //                     />
            //                 </div>

            //                 <div className="timeInput flex">
            //                     <label className="title">End Time</label>
            //                     <input
            //                         className="mt-1 block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            //                         type="time"
            //                         id="endTime"
            //                         name="endTime"
            //                         value={endTime}
            //                         min="09:00"
            //                         max="24:00"
            //                         onChange={onChange(setEndTime)}
            //                         required
            //                     />
            //                 </div>
            //             </div>

            //             <div className="timeDateInput flex">
            //                 <div className="dateInput flex">
            //                     <label className="title">Start Date</label>
            //                     <input
            //                         className="min-w-0 flex-auto rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            //                         type="date"
            //                         id="startDate"
            //                         name="startDate"
            //                         value={startDate}
            //                         onChange={onChange(setStartDate)}
            //                         required
            //                     />
            //                 </div>

            //                 <div className="dateInput flex">
            //                     <label className="title">End Date</label>
            //                     <input
            //                         className="min-w-0 flex-auto rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            //                         type="date"
            //                         id="endDate"
            //                         name="endDate"
            //                         value={endDate}
            //                         onChange={onChange(setEndDate)}
            //                         required
            //                     />
            //                 </div>
            //             </div>

            //             {selectedClassType.id !== ONLINE_CLASS_TYPE && (<SelectionDropdown className="batchSelectionDropdown" name="Lecture Hall" onSelectionChange={onSelectedHallChange} selectedItem={selectedHall} itemList={halls} nameField='lecture_hall_name' valueField='id' />)}
            //         </div>

            //         <button className='btn flex' onClick={onSubmit}>
            //             CREATE <MdAddCircleOutline className="icon" />
            //         </button>

            //     </div>

            // </>
        )
    }
    return (

        <Dialog open={isOpen} onClose={onRequestClose} className="relative z-10">

            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]: opacity - 0 data - [enter]: duration - 300 data - [leave]: duration - 200 data - [enter]: ease - out data - [leave]: ease -in "
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                            <button
                                type="button"
                                onClick={onRequestClose}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-[var(--PrimaryColor)] focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                            </button>
                        </div>

                        {renderScheduleCreate()}

                    </DialogPanel>
                </div>
            </div>

        </Dialog >
    )
}

export default BatchScheduleCreate;